import { ACCESSORIES_FETCH_SUCCESS, ACCESSORIES_RESET } from "../reducer.types";
import { IAccessoriesState } from "../../interfaces/state";
import { IReduxAwareAction } from "../../interfaces/action";

// Accessories Reducer
export const accessoriesReducer = (
    state: IAccessoriesState = { accessories: null },
    action: IReduxAwareAction
) => {
    switch (action.type) {

        case ACCESSORIES_FETCH_SUCCESS:
            return {
                loading: false,
                error: null,
                accessories: action.payload,
            };

        case ACCESSORIES_RESET:
            return {
                loading: false,
                error: null,
                accessories: null,
            };

        default:
            return state;
    }
};
