import { FC } from "react";
import { Badge, Col, Row, Table } from "react-bootstrap";
import { IVibrationControlsAware } from "../../interfaces/model";
import { defineVibrationControlsResultColor } from "../../utils";

const VibrationControlsViewer: FC<{ data: IVibrationControlsAware }> = ({ data }) => {
    const handleVibrationControlsState = () => {
        if (
            data.vibrationControlsBeforeAssembly?.cannotBeDone &&
            data.vibrationControlsAfterAssembly?.cannotBeDone
        ) {
            return "Impossibilité d'effectuer la totalité des contrôles vibratoires";
        } else if (
            data.vibrationControlsBeforeAssembly?.cannotBeDone ||
            data.vibrationControlsAfterAssembly?.cannotBeDone
        ) {
            return "Certains contrôles vibratoires n'ont pas pu être effectués";
        } else if (
            data.vibrationControlsBeforeAssembly?.hasOwnProperty(
                "reducerMinValue" &&
                    "reducerMaxValue" &&
                    "powerSupplyMinValue" &&
                    "powerSupplyMaxValue"
            ) &&
            data.vibrationControlsAfterAssembly?.hasOwnProperty(
                "reducerMinValue" &&
                    "reducerMaxValue" &&
                    "powerSupplyMinValue" &&
                    "powerSupplyMaxValue"
            )
        ) {
            return "Contrôles vibratoires effectués en totalité";
        } else if (
            !data.vibrationControlsBeforeAssembly?.hasOwnProperty(
                "reducerMinValue" &&
                    "reducerMaxValue" &&
                    "powerSupplyMinValue" &&
                    "powerSupplyMaxValue"
            ) &&
            !data.vibrationControlsAfterAssembly?.hasOwnProperty(
                "reducerMinValue" &&
                    "reducerMaxValue" &&
                    "powerSupplyMinValue" &&
                    "powerSupplyMaxValue"
            ) &&
            !data.vibrationControlsBeforeAssembly?.cannotBeDone &&
            !data.vibrationControlsAfterAssembly?.cannotBeDone
        ) {
            return "Contrôles vibratoires non effectués";
        }
    };

    return (
        <>
            {(data.vibrationControlsBeforeAssembly || data.vibrationControlsAfterAssembly) && (
                <>
                    <hr className='w-100 my-5' />
                    <Row className='mb-3'>
                        <Col>
                            <h5 className='text-center'>
                                <u>
                                    <b>Contrôles vibratoires</b>
                                </u>
                            </h5>
                            <h6 className='mt-2 text-center'>
                                <i>{handleVibrationControlsState()}</i>
                            </h6>
                        </Col>
                    </Row>
                    {data.vibrationControlsBeforeAssembly?.hasOwnProperty(
                        "reducerMinValue" &&
                            "reducerMaxValue" &&
                            "powerSupplyMinValue" &&
                            "powerSupplyMaxValue"
                    ) &&
                        !data.vibrationControlsBeforeAssembly?.cannotBeDone && (
                            <div className='mb-3'>
                                <Row>
                                    <Col>
                                        <h5>
                                            <Badge>Contrôles vibratoires avant intervention</Badge>
                                        </h5>
                                    </Col>
                                </Row>
                                <Row>
                                    <Table
                                        className='shadow-sm border-2 mt-3'
                                        responsive
                                        striped
                                        bordered
                                        hover>
                                        <thead>
                                            <tr>
                                                <th>Partie mécanique vérifiée</th>
                                                <th>Valeur minimale</th>
                                                <th>Valeur maximale</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <b>Côté réducteur</b>
                                                </td>
                                                <td style={defineVibrationControlsResultColor(data.vibrationControlsBeforeAssembly?.reducerMinValue)}>
                                                    {data.vibrationControlsBeforeAssembly?.reducerMinValue}{" "}
                                                    <i>mm/s</i>
                                                </td>
                                                <td style={defineVibrationControlsResultColor(data.vibrationControlsBeforeAssembly?.reducerMaxValue)}>
                                                    {data.vibrationControlsBeforeAssembly?.reducerMaxValue}{" "}
                                                    <i>mm/s</i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <b>Côté palier alimentation</b>
                                                </td>
                                                <td style={defineVibrationControlsResultColor(data.vibrationControlsBeforeAssembly?.powerSupplyMinValue)}>
                                                    {data.vibrationControlsBeforeAssembly?.powerSupplyMinValue}{" "}
                                                    <i>mm/s</i>
                                                </td>
                                                <td style={defineVibrationControlsResultColor(data.vibrationControlsBeforeAssembly?.powerSupplyMaxValue)}>
                                                    {data.vibrationControlsBeforeAssembly?.powerSupplyMaxValue}{" "}
                                                    <i>mm/s</i>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Row>
                            </div>
                        )}
                    {data.vibrationControlsAfterAssembly?.hasOwnProperty(
                        "reducerMinValue" &&
                            "reducerMaxValue" &&
                            "powerSupplyMinValue" &&
                            "powerSupplyMaxValue"
                    ) &&
                        !data.vibrationControlsAfterAssembly?.cannotBeDone && (
                            <>
                                <Row>
                                    <Col>
                                        <h5>
                                            <Badge>Contrôles vibratoires après intervention</Badge>
                                        </h5>
                                    </Col>
                                </Row>
                                <Row>
                                    <Table
                                        className='shadow-sm border-2 mt-3'
                                        responsive
                                        striped
                                        bordered
                                        hover>
                                        <thead>
                                            <tr>
                                                <th>Partie mécanique vérifiée</th>
                                                <th>Valeur minimal</th>
                                                <th>Valeur maximale</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <b>Côté réducteur</b>
                                                </td>
                                                <td style={defineVibrationControlsResultColor(data.vibrationControlsAfterAssembly?.reducerMinValue)}>
                                                    {data.vibrationControlsAfterAssembly?.reducerMinValue}{" "}
                                                    <i>mm/s</i>
                                                </td>
                                                <td style={defineVibrationControlsResultColor(data.vibrationControlsAfterAssembly?.reducerMaxValue)}>
                                                    {data.vibrationControlsAfterAssembly?.reducerMaxValue}{" "}
                                                    <i>mm/s</i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <b>Côté palier alimentation</b>
                                                </td>
                                                <td style={defineVibrationControlsResultColor(data.vibrationControlsAfterAssembly?.powerSupplyMinValue)}>
                                                    {data.vibrationControlsAfterAssembly?.powerSupplyMinValue}{" "}
                                                    <i>mm/s</i>
                                                </td>
                                                <td style={defineVibrationControlsResultColor(data.vibrationControlsAfterAssembly?.powerSupplyMaxValue)}>
                                                    {data.vibrationControlsAfterAssembly?.powerSupplyMaxValue}{" "}
                                                    <i>mm/s</i>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Row>
                            </>
                        )}
                    {data.vibrationControlsComment && (
                        <Row>
                            <Col>
                                <h6>
                                    <u>
                                        <b>Commentaires contrôles vibratoires :</b>
                                    </u>
                                </h6>
                                <p>{data.vibrationControlsComment}</p>
                            </Col>
                        </Row>
                    )}
                </>
            )}
        </>
    );
};

export default VibrationControlsViewer;
