import {
    INTERVENTIONS_FETCH_REQUEST,
    INTERVENTIONS_FETCH_SUCCESS,
    INTERVENTIONS_FETCH_FAILURE,
    INTERVENTIONS_UPDATE_OR_ADD,
    INTERVENTIONS_API_REQUEST_START,
    INTERVENTIONS_POST_PATCH_SUCCESS,
    INTERVENTIONS_API_OPERATION_FAILURE,
    INTERVENTIONS_POST_PATCH_UPDATE_PROGRESS,
    INTERVENTIONS_DELETE_SUCCESS,
} from "../reducer.types";

import {
    apiDeleteIntervention,
    apiGetRentalsInterventionsByRentalId,
    apiSaveIntervention,
} from "../../config/config.api.interventions";

import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { RootState } from "../../store";

import { IInterventionModel } from "../../interfaces/model";
import { IReduxAwareAction } from "../../interfaces/action";
import { AxiosResponse } from "axios";
import { isNewResource, isSyncable } from "../../utils";
import { saveImagesBeforeSync } from "../../utils/saveImagesBeforeSync";

export const updateOrCreateIntervention = (
    intervention: IInterventionModel
): IReduxAwareAction => ({
    type: INTERVENTIONS_UPDATE_OR_ADD,
    payload: intervention,
});

export const deleteIntervention = (
    intervention: IInterventionModel,
    index: number,
    userToken: string
): ThunkAction<Promise<void>, RootState, unknown, AnyAction> =>
    async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>): Promise<void> => {
        if (isNewResource(intervention)) {
            dispatch({
                type: INTERVENTIONS_DELETE_SUCCESS,
                payload: {
                    index,
                },
            });
        } else {
            dispatch({
                type: INTERVENTIONS_API_REQUEST_START,
            });

            try {
                await apiDeleteIntervention(userToken, intervention);
                dispatch({
                    type: INTERVENTIONS_DELETE_SUCCESS,
                    payload: {
                        index,
                    },
                });
            } catch (error: any) {
                dispatch({
                    type: INTERVENTIONS_API_OPERATION_FAILURE,
                    payload: {
                        index,
                        loading: false,
                        error: {
                            cathError: error,
                            responseError: error.response,
                        },
                    },
                });
            }
        }
    };

export const saveInterventions = (
    userToken: string,
    interventions: IInterventionModel[]
): ThunkAction<Promise<void>, RootState, unknown, AnyAction> =>
    async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>): Promise<void> => {
        try {
            dispatch({
                type: INTERVENTIONS_API_REQUEST_START,
            });

            const progressData = {
                progress: 0,
                total: 0
            };

            interventions.forEach((intervention: IInterventionModel, index: number) => {
                if (isSyncable(intervention)) {
                    progressData.total++;
                }

                if (intervention?.gallery?.images.length > 0) {
                    intervention?.gallery.images.forEach((image) => {
                        if (image.base64) {
                            progressData.total++;
                        }
                    });
                }
            });

            interventions.forEach(async (intervention: IInterventionModel, index: number) => {
                if (!isSyncable(intervention)) {
                    return;
                }

                const imageUploadResult = await saveImagesBeforeSync(
                    userToken,
                    intervention,
                    dispatch,
                    INTERVENTIONS_POST_PATCH_UPDATE_PROGRESS,
                    progressData
                );

                if (!imageUploadResult) {
                    dispatch({
                        type: INTERVENTIONS_API_OPERATION_FAILURE,
                        payload: {
                            index,
                            error: {
                                cathError: "Erreur lors de l'envoi d'une ou plusieurs photo(s)",
                                responseError: "Erreur lors de l'envoi d'une ou plusieurs photo(s)",
                            },
                        },
                    });

                    progressData.progress++;
                    dispatch({
                        type: INTERVENTIONS_POST_PATCH_UPDATE_PROGRESS,
                        payload: (100 * progressData.progress) / progressData.total,
                    });

                    return;
                }

                apiSaveIntervention(userToken, intervention)
                    .then((response: AxiosResponse) => {
                        dispatch({
                            type: INTERVENTIONS_POST_PATCH_SUCCESS,
                            payload: {
                                promiseData: response.data,
                                index,
                            },
                        });
                    })
                    .catch((error: any) => {
                        dispatch({
                            type: INTERVENTIONS_API_OPERATION_FAILURE,
                            payload: {
                                index,
                                error: {
                                    cathError: error,
                                    responseError: error.response,
                                },
                            },
                        });
                    })
                    .then(() => {
                        progressData.progress++;

                        dispatch({
                            type: INTERVENTIONS_POST_PATCH_UPDATE_PROGRESS,
                            payload: (100 * progressData.progress) / progressData.total,
                        });
                    });
            });
        } catch (error: any) {
            console.log(error);
            dispatch({
                type: INTERVENTIONS_API_OPERATION_FAILURE,
                payload: {
                    index: 0,
                    error: {
                        responseError: "Erreur inconnue: " + (error.toString !== undefined ? error.toString() : ''),
                    }
                }
            });
        }

    };
