// Redux Interventions Types
export const INTERVENTIONS_FETCH_REQUEST = "INTERVENTIONS_FETCH_REQUEST";
export const INTERVENTIONS_FETCH_SUCCESS = "INTERVENTIONS_FETCH_SUCCESS";
export const INTERVENTIONS_FETCH_FAILURE = "INTERVENTIONS_FETCH_FAILURE";
export const INTERVENTIONS_RESET = "INTERVENTIONS_RESET";
export const INTERVENTIONS_UPDATE_OR_ADD = "INTERVENTIONS_UPDATE_OR_ADD";
export const INTERVENTIONS_DELETE_SUCCESS = "INTERVENTIONS_DELETE_SUCCESS";
export const INTERVENTIONS_API_REQUEST_START = "INTERVENTIONS_API_REQUEST_START";
export const INTERVENTIONS_POST_PATCH_UPDATE_PROGRESS = "INTERVENTIONS_POST_PATCH_UPDATE_PROGRESS";
export const INTERVENTIONS_POST_PATCH_SUCCESS = "INTERVENTIONS_POST_PATCH_SUCCESS";
export const INTERVENTIONS_API_OPERATION_FAILURE = "INTERVENTIONS_API_OPERATION_FAILURE";
export const INTERVENTIONS_POST_PATCH_RESET = "INTERVENTIONS_POST_PATCH_RESET";
export const INTERVENTIONS_LOADING_CANCEL = "INTERVENTIONS_LOADING_CANCEL";
