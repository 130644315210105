import styled, { css, keyframes } from "styled-components";

const flashAnimation = keyframes`
    from {
      opacity: 0.75;
    }

    to {
        opacity: 0;
    }
`;


export const Overlay = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  bottom: 20px;
  box-shadow: 0 0 20px 50px rgba(0, 0, 0, 0.5);
  border: 1px solid white;
  border-radius: 10px;
`;


export const ImageErrorLayout = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 10px;
`;


interface FlashProps {
  readonly flash: boolean;
}

export const Flash = styled.div<FlashProps>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  background: white;

  ${({ flash }) => {
    if (flash) {
      return css`
        animation: ${flashAnimation} 750ms ease-out;
      `;
    }
  }}
`;
