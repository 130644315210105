import { apiBaseUrl } from "./config.api.baseUrl";

export const baseCheckpointIri = "/api/checkpoints";

// Create Get Request for Checkpoints
export const apiGetCheckpoints = async (userToken: string) => {
    const response = await apiBaseUrl(userToken).get("/api/checkpoints");

    return response.data;
};
