import { useFormikContext } from "formik";
import { FC } from "react";
import { Button, Modal } from "react-bootstrap";
import ReactRouterPrompt from "react-router-prompt";

const PromptIfDirty: FC = () => {
    const { dirty, isSubmitting } = useFormikContext();
    const when = dirty && !isSubmitting;
    return (
        <ReactRouterPrompt when={when}>
            {({ isActive, onConfirm, onCancel }) => (
                <>
                    <Modal
                        show={isActive}
                        onHide={() => onCancel}
                        backdrop="static"
                        keyboard={false}
                    >
                        <Modal.Body>
                            Attention toutes vos modifications seront perdues, voulez vous vraiment quitter ?
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="outline-primary" onClick={onCancel}>
                                Rester sur le formulaire
                            </Button>
                            <Button variant="danger" className="text-white" onClick={onConfirm}>Oui, quitter</Button>
                        </Modal.Footer>
                    </Modal>
                </>
            )}
        </ReactRouterPrompt>
    )
};

export default PromptIfDirty;
