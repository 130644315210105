import { ChangeEvent, FC, useEffect, useMemo, useState } from "react";
import ReactSignatureCanvas from "react-signature-canvas";
import { Field, useFormikContext } from "formik";
import { ISignaturePad } from "../interfaces/component.props/ISignaturePad";
import { Button, Col } from "react-bootstrap";
import { Check, Eraser } from "react-bootstrap-icons";
import FormError from "./Form/FormError";

const SignaturePad: FC<ISignaturePad> = ({ signatoryInfo, handleSubmit, path }) => {
    let signaturePad = useMemo(() => {
        return {} as ReactSignatureCanvas;
    }, []);

    const { setFieldValue } = useFormikContext();
    const [showPad, setShowPad] = useState(true);
    const [canvasEnabled, setCanvasEnabled] = useState(true);

    //clear pad if path has changed / unabled, disabled about input focusing
    useEffect(() => {
        if (signaturePad.clear !== undefined) {
            signaturePad.clear();
        }
        canvasEnabled ? signaturePad.on() : signaturePad.off();
    }, [path, signaturePad, canvasEnabled]);

    return (
        <>
            <div className='d-flex flex-column align-items-center'>
                <div>
                    {signatoryInfo && (
                        <Col className='mb-3'>
                            <label htmlFor='dischargeCustomer'>
                                <Field
                                    type='checkbox'
                                    name='discharge'
                                    className='me-4 form-check-input'
                                    onClick={(e: ChangeEvent<HTMLInputElement>) => {
                                        setShowPad(!e.target.checked);
                                        signaturePad.clear();
                                        setFieldValue("signatory_fullname", "");
                                        setFieldValue("content", "");
                                    }}
                                />
                                Signataire absent
                            </label>
                        </Col>
                    )}
                    <div
                        style={{
                            display: showPad ? "flex" : "none",
                            flexDirection: "column",
                            alignItems: "center",
                            maxWidth: "90vw",
                        }}>
                        {signatoryInfo && (
                            <>
                                <Field
                                    name='signatory_fullname'
                                    type='text'
                                    className='form-control shadow-sm mb-3'
                                    autoComplete='off'
                                    onFocus={() => setCanvasEnabled(false)}
                                    onBlur={() => setCanvasEnabled(true)}
                                    placeholder='Nom & prénom du signataire'
                                />
                                <FormError path='signatory_fullname' />
                            </>
                        )}
                        <ReactSignatureCanvas
                            ref={(ref) => ref && (signaturePad = ref)}
                            onEnd={() => {
                                setFieldValue(
                                    "content",
                                    signaturePad.isEmpty()
                                        ? ""
                                        : signaturePad.getTrimmedCanvas().toDataURL("image/png")
                                );
                            }}
                            canvasProps={{
                                className: "border rounded shadow-sm signature-canvas border-grey",
                                style: {
                                    width: "100%",
                                    height: "250px",
                                },
                            }}
                        />
                        <FormError path='content' />
                    </div>
                    <Col style={{ display: "none" }}>
                        <Field type='text' name='content' />
                    </Col>
                    <Col className='d-flex justify-content-center'>
                        <div className='btn-group shadow mt-3'>
                            {showPad && (
                                <Button
                                    variant='danger border-0 text-white'
                                    onClick={() => {
                                        signaturePad.clear();
                                        setFieldValue("content", "");
                                    }}>
                                    <Eraser className='me-2' />
                                    Effacer
                                </Button>
                            )}
                            <Button
                                variant='success border-0'
                                onClick={() => {
                                    if (signaturePad.isEmpty()) {
                                        setFieldValue("content", "");
                                    }
                                    handleSubmit();
                                }}>
                                <Check className='me-2' />
                                Valider
                            </Button>
                        </div>
                    </Col>
                </div>
            </div>
        </>
    );
};

export default SignaturePad;
