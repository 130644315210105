import { FC } from 'react'
import { IMaintenancesModel } from '../../interfaces/model'

const ViewerExtraSpareParts: FC<{ data: IMaintenancesModel }> = ({ data }) => {
  return (
    <>
        {data.extraSpareParts && data.extraSpareParts?.length ? (
            <>
                <hr className='w-100 my-5' />
                <h6>
                    <u>
                        <b>Pièces détachées hors contrat maintenance :</b>
                    </u>
                </h6>
                <ul>
                    {data.extraSpareParts.map((extraSparePart) => (
                        <li key={crypto.randomUUID()}>
                            {extraSparePart.name}
                        </li>
                    ))}
                </ul>
            </>
        ) : null}
    </>
  )
}

export default ViewerExtraSpareParts;