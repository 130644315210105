import { FC } from "react";
import { IModalConfirmation } from "../../interfaces/component.props/modal/IModal.confirmation";
import { ModalContainer } from "../Styled/Modal.Container";
import { Button, Modal } from "react-bootstrap";

const ModalConfirmation: FC<IModalConfirmation> = ({
    title,
    textContent,
    show,
    confirmText,
    cancelText,
    closeCallBack,
    confirmCallBack,
}) => {
    return (
        <>
            <ModalContainer>
                <Modal
                    show={show}
                    onHide={() => closeCallBack}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header>
                        <Modal.Title>{title ?? "Confirmation"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>{textContent}</p>
                    </Modal.Body>
                    <Modal.Footer>
                        {confirmCallBack ? (
                            <>
                                <Button
                                    variant='outline-primary'
                                    onClick={() => closeCallBack(false)}>
                                    {cancelText ?? "Annuler"}
                                </Button>
                                <Button
                                    variant='danger'
                                    className='text-white'
                                    onClick={confirmCallBack}>
                                    {confirmText ?? "Confirmer"}
                                </Button>
                            </>
                        ) : (
                            <Button variant='primary' onClick={() => closeCallBack(false)}>
                                Fermer
                            </Button>
                        )}
                    </Modal.Footer>
                </Modal>
            </ModalContainer>
        </>
    );
};

export default ModalConfirmation;
