import axios, { AxiosRequestConfig } from "axios";
import { IIdAware } from "../interfaces/model";
import { isNewResource, transformObjectApiFormat } from "../utils";

// Resolve env variables
const { REACT_APP_API_BASE_URL } = process.env;

// Create Instance of Axios for API calls
export const apiBaseUrl = (userToken?: string) => {
    let headers: any = {
        "Content-Type": "application/json",
        Accept: "application/json",
    };

    if (userToken) {
        headers = {...headers, Authorization: `Bearer ${userToken}`};
    }

    return axios.create({
        baseURL: REACT_APP_API_BASE_URL,
        timeout: 10000,
        headers: headers,
    });
};

export const defaultSaveConfig = (
    resource: IIdAware,
    baseIri: string,
    iriConfig: any[] | null
): AxiosRequestConfig => {
    const isNew = isNewResource(resource);
    const method = isNew ? "POST" : "PATCH";
    const data = iriConfig ? transformObjectApiFormat(resource, iriConfig, method) : { ...resource };
    const url = isNew ? baseIri : `${baseIri}/${resource.id}`;
    const headers = {
        "Content-Type": isNew ? "application/json" : "application/merge-patch+json",
    };

    delete data.id;

    const config: any =  {
        method,
        data,
        url,
        headers,
    };

    if (isNew) {
        config.transformRequest = [
            function (data: any, headers: any) {
                return JSON.stringify(data);
            },
        ];
    }

	return config;
};
