import { apiBaseUrl } from "./config.api.baseUrl";
import { ISearchReferenceForm } from "../interfaces/form/ISearchReferenceForm";
import { IMaterialModel } from "../interfaces/model";

export const baseMaterialIri = "/api/materials";

// Create Get Request for Material by Reference with Children
export const apiGetMaterialByMaterialReference = async (
    userToken: string,
    reference: ISearchReferenceForm | string
) => {
    const response = await apiBaseUrl(userToken).get(
        `/api/materials?withChildren=true&reference=${reference}`
    );
    return response.data;
};

// Create Get Request for Material by Id with Children
export const apiGetMaterialByMaterialId = async (userToken: string, id: IMaterialModel["id"]) => {
    const response = await apiBaseUrl(userToken).get(`${baseMaterialIri}${id}`);
    return response.data;
};
