import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import localForage from "localforage";
import { persistReducer, persistStore } from "redux-persist";

import {
    userLoginReducer,
    accessoriesReducer,
    checkpointsReducer,
    materialReducer,
    countriesReducer,
    maintenancesReducer,
    rentalReducer,
    interventionsReducer,
} from "./redux/reducers";
import { composeWithDevTools } from "redux-devtools-extension";


const db = localForage.createInstance({
    name: "lcmiapp",
    driver: localForage.INDEXEDDB,
});

const storage = {
    db,
    getItem: db.getItem,
    setItem: db.setItem,
    removeItem: db.removeItem
};
const persistConfig = {
    key: "root",
    storage: storage,
};

const reducers = combineReducers({
    userLogin: userLoginReducer,
    accessories: accessoriesReducer,
    checkpoints: checkpointsReducer,
    countries: countriesReducer,
    material: materialReducer,
    maintenances: maintenancesReducer,
    rental: rentalReducer,
    interventions: interventionsReducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

const middleware = [thunk];

const composeRes =
    process.env.NODE_ENV !== "production"
        ? composeWithDevTools(applyMiddleware(...middleware))
        : compose(applyMiddleware(...middleware));
const store = createStore(persistedReducer, composeRes);
let persistor = persistStore(store);

export { store, persistor,db };
export type RootState = ReturnType<typeof store.getState>;
