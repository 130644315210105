import { ThunkDispatch } from "redux-thunk";
import { apiSaveImage } from "../config/config.api.mediaObject";
import { IImagesModel, IInterventionModel, IMaintenancesModel } from "../interfaces/model";
import { RootState } from "../store";
import { AnyAction } from "@reduxjs/toolkit";

export const saveImagesBeforeSync = async (
    userToken: string,
    workingEvent: IMaintenancesModel | IInterventionModel,
    dispatch: ThunkDispatch<RootState, unknown, AnyAction>,
    dispatchType: string,
    progressData: any
): Promise<boolean> => {
    if (!workingEvent.gallery || workingEvent.gallery.images?.length === 0) {
        return true;
    }

    const images = workingEvent.gallery.images;
    let hasImageUploadError = false;

    if (!images) {
        return true;
    }

    for await (const [key, image] of images.entries()) {
        if (!image.base64) {
            continue;
        }

        try {
            const response = await apiSaveImage(userToken, image);
            const newImageData = await response.data;

            images[key] = newImageData as IImagesModel;
        } catch (error: any) {
            hasImageUploadError = true;
            images[key] = {
                ...image,
                error: error.response?.data?.violations[0]?.message || error.message,
            };
        }

        progressData.progress++;
        dispatch({
            type: dispatchType,
            payload: (100 * progressData.progress) / progressData.total,
        });

        workingEvent.gallery.images = images;
    }

    return !hasImageUploadError;
};
