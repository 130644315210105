import { FC } from "react";
import { Table } from "react-bootstrap";
import { Col, Container, Row } from "react-bootstrap";
import { CheckSquare, Square } from "react-bootstrap-icons";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Footer from "../components/Footer/Footer";
import NavbarMenu from "../components/Navbar/Navbar.menu";
import { TitleLayout } from "../components/Styled/Title.Layout";
import GalleryViewer from "../components/Viewer/Viewer.Gallery";
import SignatureViewer from "../components/Viewer/Viewer.Signature";
import VibrationControlsViewer from "../components/Viewer/Viewer.VibrationControls";
import { IInterventionModel } from "../interfaces/model";
import { selectInterventionById } from "../redux/selectors";
import { RootState } from "../store";
import { dateTransformer, materialStateLabel, interventionTypeLabel, userToString } from "../utils";

const RentalInterventionViewer: FC = () => {
    const id = useParams().id;
    const state = useSelector<RootState, RootState>((state) => state);
    const intervention = selectInterventionById(state, id) as IInterventionModel;

    return (
        <>
            <TitleLayout>
                <NavbarMenu navbarTitle="Détails de l'intervention" />
                <h2 className='title'>
                    <u>Rapport de intervention</u>
                </h2>
                <Container className='container-title-layout'>
                    <Table className='shadow-sm border-2' responsive striped bordered hover>
                        <tbody>
                            <tr>
                                <th>
                                    <b>Type</b>
                                </th>
                                <td>{interventionTypeLabel(intervention.type)}</td>
                            </tr>
                            <tr>
                                <th>
                                    <b>Date</b>
                                </th>
                                <td>{dateTransformer(intervention.date)}</td>
                            </tr>
                            <tr>
                                <th>
                                    <b>Technicien</b>
                                </th>
                                <td>{userToString(intervention.technician)}</td>
                            </tr>
                            <tr>
                                <th>
                                    <b>Nombres d'heures d'utilisation</b>
                                </th>
                                <td>{intervention.nbHours}</td>
                            </tr>
                        </tbody>
                    </Table>
                    <VibrationControlsViewer data={intervention} />
                    {intervention.comment && (
                        <>
                            <hr className='w-100 my-5' />
                            <Row>
                                <Col>
                                    <h6>
                                        <u>
                                            <b>Commentaires :</b>
                                        </u>
                                    </h6>
                                    <p>{intervention.comment}</p>
                                </Col>
                            </Row>
                        </>
                    )}
                    <hr className='w-100 my-5' />
                    <h5 className='text-center'>
                        <u>
                            <b>Points de contrôles</b>
                        </u>
                    </h5>
                    {intervention.checkpoints &&
                        intervention.checkpoints.map((checkpointItem) => (
                            <div key={checkpointItem.checkpoint.id}>
                                {checkpointItem.value ? <CheckSquare /> : <Square />}
                                <span className='ms-2'>{checkpointItem.checkpoint?.name}</span>
                            </div>
                        ))}

                    {intervention.checkpointsComment && (
                        <Row className='mt-2'>
                            <Col>
                                <h6>
                                    <u>
                                        <b>Commentaires :</b>
                                    </u>
                                </h6>
                                <p>{intervention.checkpointsComment}</p>
                            </Col>
                        </Row>
                    )}

                    {intervention.accessories && intervention.accessories.length > 0 && (
                        <>
                            <hr className='w-100 my-5' />
                            <h5 className='text-center'>
                                <u>
                                    <b>Accessoires</b>
                                </u>
                            </h5>
                            <Table striped bordered>
                                <thead>
                                    <tr>
                                        <th>Accessoire</th>
                                        <th>Qte</th>
                                        <th>État</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {intervention.accessories &&
                                        intervention.accessories.map((accessoryItem) => (
                                            <tr key={accessoryItem.accessory.id}>
                                                <td>{accessoryItem.accessory.name}</td>
                                                <td>{accessoryItem.quantity}</td>
                                                <td>{materialStateLabel(accessoryItem.state)}</td>
                                            </tr>
                                        ))}
                                </tbody>
                            </Table>

                            {intervention.accessoriesComment && (
                                <Row className='mt-2'>
                                    <Col>
                                        <h6>
                                            <u>
                                                <b>Commentaires :</b>
                                            </u>
                                        </h6>
                                        <p>{intervention.accessoriesComment}</p>
                                    </Col>
                                </Row>
                            )}
                        </>
                    )}
                    <GalleryViewer data={intervention} />
                    <SignatureViewer data={intervention} />
                </Container>
                <Footer type='back' />
            </TitleLayout>
        </>
    );
};

export default RentalInterventionViewer;
