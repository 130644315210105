import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectInterventionToSync, selectMaintenancesToSync, selectMaterial, selectUserToken } from "../redux/selectors";
import { RootState } from "../store";
import { Link, useNavigate } from "react-router-dom";
import { getMaterialData, logout } from "../redux/actions";
import { QrReader } from "react-qr-reader";
import { Formik, Form, Field, FormikHelpers } from "formik";
import { validationSearchReferenceSchema } from "../validation/scan.schema";
import NavbarTitle from "../components/Navbar/Navbar.title";
import ModalErrorNetwork from "../components/Modal/Modal.error.network";
import ModalConfirmation from "../components/Modal/Modal.confirmation";
import Logo from "../assets/img/Logo.png";
import { Button } from "react-bootstrap";
import { BaseLayout } from "../components/Styled/Base.Layout";
import { LogoContainer } from "../components/Styled/Logo.Container";
import FormRow from "../components/Form/FormRow";
import { ExclamationTriangleFill } from "react-bootstrap-icons";

const Scan: FC = () => {
    // Redux state
    const state = useSelector<RootState, RootState>((state) => state);
    const userToken = selectUserToken(state);
    const countMaintenanceToSync: number = selectMaintenancesToSync(state)?.length ?? 0;
    const countInterventionToSync: number = selectInterventionToSync(state)?.length ?? 0;
    const { loading, error, material } = selectMaterial(state);

    //components state
    const [showModalConfirmation, setShowModalConfirmation] = useState<boolean>(false);
    const [formikHelpers, setFormikHelpers] = useState<FormikHelpers<any> | null>(null);
    const [forceSubmit, setForceSubmit] = useState<boolean>(false);

    // hooks
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (formikHelpers && !loading) {
            if (error) {
                formikHelpers.setFieldError("searchReference", "Identifiant de la machine invalide");
            } else {
                navigate('/home');
            }
            formikHelpers.setSubmitting(false);
            setFormikHelpers(null);
        }

    }, [loading, formikHelpers]);

    const handleSubmit = (values: any, formikHelpers: FormikHelpers<any>): void | Promise<any> => {
        if (!forceSubmit && (countMaintenanceToSync > 0 || countInterventionToSync > 0)) {
            setShowModalConfirmation(true);
            return;
        }

        dispatch(getMaterialData(userToken!, values.searchReference));
        setFormikHelpers(formikHelpers);
        setForceSubmit(false);
    }

    return (
        <BaseLayout>
            <NavbarTitle title="RECHERCHE MACHINE" />
            <Formik
                initialValues={{ searchReference: "" }}
                validationSchema={validationSearchReferenceSchema}
                onSubmit={handleSubmit}
            >
                {({ setFieldValue, submitForm, isSubmitting, setSubmitting }) => (
                    <Form className="d-flex flex-column justify-content-center" style={{ maxWidth: '280px' }}>
                        <QrReader
                            onResult={(result) => {
                                if (result && result.getText()) {
                                    setFieldValue("searchReference", result.getText());
                                    submitForm();
                                }
                            }}
                            containerStyle={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "180px",
                                overflow: "hidden",
                                border: "2px solid red",
                                borderRadius: "10px",
                            }}
                            constraints={{
                                facingMode: "environment",
                            }}
                            className="shadow"
                        />
                        <h5 className="mt-4">Recherche Manuelle de la Machine</h5>
                        <div className="form-group required text-center">
                            <label htmlFor="searchReference" className="my-2">
                                Saisissez l'identifiant de la machine
                            </label>
                            <FormRow name="searchReference">
                                <Field
                                    type="text"
                                    name="searchReference"
                                    className="form-control shadow-sm"
                                    id="searchReference"
                                    placeholder="identifiant de la machine"
                                />

                            </FormRow>
                        </div>
                        <Button
                            className="mt-4"
                            variant={isSubmitting ? "warning" : "primary"}
                            type="submit"
                            disabled={isSubmitting}
                        >
                            {isSubmitting ? "Recherche..." : "Rechercher"}
                        </Button>
                        <Button
                            className="mt-5 text-white"
                            variant="danger"
                            onClick={() => dispatch(logout())}
                        >
                            Se déconnecter
                        </Button>
                        <ModalConfirmation
                            title="Vous avez des données non synchronisées !"
                            textContent="Si vous validez, vous perdrez toutes les données non synchronisées. Voulez-vous lancer la recherche?"
                            confirmText="Oui, perdre les données non synchronisées"
                            cancelText="Annuler"
                            show={showModalConfirmation}
                            closeCallBack={() => {
                                setSubmitting(false);
                                setShowModalConfirmation(false);
                            }}
                            confirmCallBack={() => {
                                setForceSubmit(true);
                                submitForm();
                                setShowModalConfirmation(false);
                            }}
                        />
                        {(countMaintenanceToSync > 0 || countInterventionToSync > 0) ? (
                            <div className="d-flex flex-column mt-5">
                                <p className="text-center"><b>{material?.name}:</b></p>
                                {countMaintenanceToSync ? (
                                    <p className="text-danger">
                                        <ExclamationTriangleFill className="me-2" />
                                        Vous avez {countMaintenanceToSync} maintenance(s) non synchronisée(s) !
                                    </p>
                                ) : null}
                                {countInterventionToSync ? (
                                    <p className="text-danger">
                                        <ExclamationTriangleFill className="me-2" />
                                        Vous avez {countInterventionToSync} intervention(s) non synchronisée(s) !
                                    </p>
                                ) : null}
                                <Link
                                    className="btn btn-primary"
                                    to="/home"
                                >
                                    Retour à l'accueil
                                </Link>
                            </div>
                        ) : null}
                    </Form>
                )}
            </Formik>
            <LogoContainer src={Logo} alt="logo LCMI" style={{marginTop: '50px'}} />
            <ModalErrorNetwork />
        </BaseLayout>
    );
};

export default Scan;
