import { IMaterialModel, IVibrationControlsModel } from "../interfaces/model";
import { getResourceId } from "./api";

export const materialStates = {
    good: "good",
    average: "average",
    bad: "bad",
    replaced: "replaced",
};

export const operatingHours = {
    OH1x8_5D: "OH1x8_5D",
    OH2x8_5D: "OH2x8_5D",
    OH3x8_5D: "OH3x8_5D",
    OH3x8_7D: "OH3x8_7D",
};

export const materialChildrenIds = (material: IMaterialModel, includeSelf: boolean = true): (string | number)[] => {
    let ids: (string | number)[] = [];

    if (includeSelf) {
        ids.push(getResourceId(material));
    }

    if (material.children) {
        material.children.forEach((child: IMaterialModel) => {
            ids = ids.concat(materialChildrenIds(child));
        });
    }

    return ids;
}


export const materialStateLabel = (state: string): string => {
    switch (state) {
        case materialStates.good:
            return "Bon";
        case materialStates.average:
            return "Moyen";
        case materialStates.bad:
            return "Mauvais";
        case materialStates.replaced:
            return "Remplacé";
        default:
            return "Inconnu";
    }
};

export const materialStateColor = (state: string): string => {
    switch (state) {
        case materialStates.good:
            return "success";
        case materialStates.average:
            return "warning";
        case materialStates.bad:
        case materialStates.replaced:
            return "danger";
        default:
            return "light";
    }
}


export const operationHoursLabel = (operatingHour: string): string => {
    switch (operatingHour) {
        case operatingHours.OH1x8_5D:
            return "8h sur 5 jours";
        case operatingHours.OH2x8_5D:
            return "2*8h sur 5 jours";
        case operatingHours.OH3x8_5D:
            return "3*8h sur 5 jours";
        case operatingHours.OH3x8_7D:
            return "3*8h sur 7 jours";
        default:
            return "";
    }
};


export const translateMaterialInterventionType = (interventionType: string): string => {
    switch (interventionType) {
        case "maintenance":
            return "Maintenance";
        case "repair":
            return "Réparation";
        case "both":
            return "Maintenance et réparation";
        default:
            return "Inconnu";
    }
};

export const createMaterialHierarchy = (
    material: IMaterialModel | null,
    materialChildren: IMaterialModel[] | null
) => {
    let materialChildrensMappingHierarchy: any[] = [];
    let materialParentsMappingHierarchy: IMaterialModel[] = [];

    if (material && materialChildren) {
        material.children?.forEach((child: IMaterialModel) => {
            materialParentsMappingHierarchy.push(child);
        });

        materialChildren.forEach((child) => {
            materialParentsMappingHierarchy.forEach((parent, index) => {
                child.children?.forEach((grandChild: IMaterialModel) => {
                    if (child.id === parent.id) {
                        materialChildrensMappingHierarchy[index] = [
                            ...(materialChildrensMappingHierarchy[index] || []),
                            grandChild,
                        ];
                    }
                });
            });
        });
    }

    return { materialParentsMappingHierarchy, materialChildrensMappingHierarchy };
};

export const getVisibleChildrenForMaintenanceNbHours = (material: IMaterialModel, maintenanceNbHours: number): IMaterialModel[] => {
    if (!maintenanceNbHours) {
        return [];
    }

    return material?.children!.filter((child: IMaterialModel) => {
        return child.maintenanceNbHours &&
            (child.maintenanceNbHours.includes(+maintenanceNbHours) || child.maintenanceNbHours.length === 0);
    });
};


export const createNewVibrationControls = (values: any = null): IVibrationControlsModel => {
    return {
        cannotBeDone: false,
        reducerMinValue: null,
        reducerMaxValue: null,
        powerSupplyMinValue: null,
        powerSupplyMaxValue: null,
    };
}