import { FC } from "react";
import ListItemViewer from "./Viewer.ListItem";
import { Badge, Col, ListGroup, Row } from "react-bootstrap";
import { IViewerListGroup } from "../../../interfaces/component.props/viewer/list/IViewerListGroup";
import { translateMaterialInterventionType } from "../../../utils";

const ListGroupViewer: FC<IViewerListGroup> = ({
    materialParentsMappingHierarchy,
    materialChildrensMappingHierarchy,
    maintenance,
}) => {
    const handleChildrenMappingIsDefined = (index: number): any => {
        if (materialChildrensMappingHierarchy[index]?.length > 0) {
            return materialChildrensMappingHierarchy[index];
        } else {
            return [];
        }
    };

    return (
        <>
            {materialParentsMappingHierarchy && (
                <>
                    {materialParentsMappingHierarchy.map(
                        (materialParent: any, index) => (
                            (
                                <ListGroup
                                    as='ul'
                                    className='bg-secondary text-white my-4 shadow-sm'
                                    key={index}>
                                    <Row className='mx-2 my-3'>
                                        <Col className='d-flex justify-content-between align-items-center'>
                                            <h5>
                                                {materialParent.material?.name ?? materialParent.name}{" "}-
                                                <i>
                                                    {" "}Ref :{" "}
                                                    {materialParent.material?.reference ?? materialParent.reference}
                                                </i>
                                            </h5>
                                            <Badge className='bg-primary'>
                                                {maintenance.materialItems?.map((materialItem) => {
                                                    if (
                                                        materialItem.material.id === materialParent.id &&
                                                        materialItem.interventionType
                                                    ) {
                                                        return translateMaterialInterventionType(
                                                            materialItem.interventionType
                                                        );
                                                    }

                                                    return null;
                                                })}
                                            </Badge>
                                        </Col>
                                    </Row>
                                    {materialChildrensMappingHierarchy && (
                                        <ListItemViewer
                                            materialChildrensMappingHierarchy={handleChildrenMappingIsDefined(index)}
                                            maintenance={maintenance}
                                        />
                                    )}
                                </ListGroup>
                            )
                        )
                    )}
                </>
            )}
        </>
    );
};

export default ListGroupViewer;
