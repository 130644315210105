import { FC, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import { selectMaintenancesList, selectMaterialItem, selectUser, selectUserToken } from "../redux/selectors";
import { deleteMaintenance, saveMaintenances } from "../redux/actions";
import {
    isSyncable,
    canEditMaintenance,
    canDeleteMaintenance,
    isMaintenanceComplete,
    getValueAtPath,
    dateTransformer,
} from "../utils";
import NavbarMenu from "../components/Navbar/Navbar.menu";
import { FooterLayout } from "../components/Styled/Footer.Layout";
import { TitleLayout } from "../components/Styled/Title.Layout";
import OverlayLoading from "../components/OverlayLoading";
import { Button, Col, Container, Row, Table, Spinner, Modal, Dropdown } from "react-bootstrap";
import { EyeFill, InfoCircle, Pencil, PencilSquare, Trash } from "react-bootstrap-icons";
import SyncState from "../components/SyncState";
import ModalConfirmation from "../components/Modal/Modal.confirmation";
import { IUserModel } from "../interfaces/model";
import BooleanBadge from "../components/BooleanBadge";
import { MAINTENANCES_LOADING_CANCEL } from "../redux/reducer.types";

const MaintenancesList: FC = () => {
    // Handle navigate
    const navigate = useNavigate();

    // Handle Material State Data
    const state = useSelector<RootState, RootState>((state) => state);
    const material = selectMaterialItem(state);
    const maintenances = selectMaintenancesList(state);
    const userToken = selectUserToken(state);
    const user = selectUser(state) as IUserModel;
    const showSyncBtn = maintenances && maintenances.filter((i) => isSyncable(i)).length > 0;
    const { loading, progressApiCall } = state.maintenances;

    const [currentError, setCurrentError] = useState<any>(null);
    const violations = getValueAtPath(currentError, "responseError.data.violations") as
        | null
        | any[];
    const errorDetail = currentError && typeof currentError.responseError === 'string' ?
        currentError.responseError :
        getValueAtPath(currentError, "responseError.data.detail");


    const [confirmDelete, setConfirmDelete] = useState<any>({
        show: false,
    });

    // Handle Sync Maintenances
    const dispatch = useDispatch();
    const handleSyncMaintenances = () => {
        if (maintenances?.length && userToken) {
            dispatch(saveMaintenances(userToken, maintenances));
        }
    };

    return (
        <>
            <OverlayLoading 
                isOpen={loading} 
                progressPercent={progressApiCall} 
                forceClose={() => dispatch({ type: MAINTENANCES_LOADING_CANCEL })}    
            />
            <TitleLayout>
                <NavbarMenu navbarTitle='Liste des maintenances' />
                <h2 className='title'>Liste des maintenances {material?.reference}</h2>
                <Container className='container-title-layout'>
                    <Table responsive striped bordered hover>
                        <thead>
                            <tr>
                                <th className='text-center'>Date</th>
                                <th className='text-center'>Tech</th>
                                <th className='text-center'>État</th>
                                <th className='text-center'>Complète</th>
                                <th className='text-center'>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {maintenances?.length === 0 && (
                                <tr>
                                    <td colSpan={5} className='text-center'>
                                        Aucune maintenance
                                    </td>
                                </tr>
                            )}
                            {maintenances?.map((maintenance, index) => (
                                <tr key={maintenance.id}>
                                    <td className='text-center'>
                                        <Link to={`/maintenance/${maintenance.id}/show`}>
                                            {dateTransformer(maintenance.date)}
                                        </Link>
                                    </td>
                                    <td className='text-center'>
                                        {maintenance.technician?.username}
                                    </td>
                                    <td className='text-center'>
                                        <SyncState state={maintenance.syncState!} />
                                    </td>
                                    <td className='text-center'>
                                        <BooleanBadge value={isMaintenanceComplete(maintenance)} />
                                    </td>
                                    <td className='text-center'>
                                        <Dropdown>
                                            <Dropdown.Toggle size='sm' id='dropdown-basic'>
                                                Actions
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item
                                                    onClick={() => {
                                                        navigate(
                                                            `/maintenance/${maintenance.id}/show`
                                                        );
                                                    }}>
                                                    <EyeFill /> Voir
                                                </Dropdown.Item>
                                                {canEditMaintenance(maintenance, user) && (
                                                    <Dropdown.Item
                                                        onClick={() => {
                                                            navigate(
                                                                `/maintenance/${maintenance.id}/edit`
                                                            );
                                                        }}>
                                                        <Pencil /> Modifier
                                                    </Dropdown.Item>
                                                )}
                                                {canEditMaintenance(maintenance, user) && (
                                                    <Dropdown.Item
                                                        onClick={() => {
                                                            navigate(
                                                                `/maintenance/${maintenance.id}/signatures`
                                                            );
                                                        }}>
                                                        <PencilSquare /> Signer
                                                    </Dropdown.Item>
                                                )}
                                                {maintenance.error && (
                                                    <Dropdown.Item
                                                        onClick={() =>
                                                            setCurrentError(maintenance.error)
                                                        }>
                                                        <InfoCircle /> Erreurs
                                                    </Dropdown.Item>
                                                )}
                                                {canDeleteMaintenance(maintenance, user) && (
                                                    <Dropdown.Item
                                                        onClick={() =>
                                                            setConfirmDelete({
                                                                show: true,
                                                                confirmCallBack: () => {
                                                                    dispatch(
                                                                        deleteMaintenance(
                                                                            maintenance,
                                                                            index,
                                                                            userToken!
                                                                        )
                                                                    );
                                                                    setConfirmDelete({
                                                                        show: false,
                                                                    });
                                                                },
                                                            })
                                                        }>
                                                        <Trash /> Supprimer
                                                    </Dropdown.Item>
                                                )}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Container>
                <FooterLayout style={{ height: "100px" }}>
                    <Container fluid>
                        <Row>
                            <Col className='text-center'>
                                <Button
                                    variant='primary'
                                    className='w-100'
                                    onClick={() => navigate("/maintenance/create")}>
                                    Créer une maintenance
                                </Button>
                            </Col>
                        </Row>
                        {showSyncBtn && (
                            <Row>
                                <Col className='text-center'>
                                    <Button
                                        variant='primary'
                                        className='w-100 mt-2'
                                        onClick={handleSyncMaintenances}>
                                        {loading && (
                                            <Spinner
                                                className='me-5'
                                                as='span'
                                                animation='border'
                                                size='sm'
                                                role='status'
                                                aria-hidden='true'
                                            />
                                        )}
                                        Synchroniser
                                    </Button>
                                </Col>
                            </Row>
                        )}
                    </Container>
                </FooterLayout>
            </TitleLayout>
            <ModalConfirmation
                title='Confirmation'
                textContent='Êtes vous sûr de vouloir supprimer cette maintenance'
                closeCallBack={() =>
                    setConfirmDelete((prevState: any) => ({
                        ...prevState,
                        show: false,
                    }))
                }
                {...confirmDelete}
            />
            <Modal show={currentError !== null} onHide={() => setCurrentError(null)}>
                <Modal.Header closeButton>
                    <Modal.Title>Erreur</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{currentError?.cathError?.message}</p>
                    <ul>
                        {violations && violations.length !== undefined && violations.length ? (
                            violations.map((violation) => (
                                <li className='text-danger'>
                                    {violation.propertyPath}: {violation.message}
                                </li>
                            ))
                        ) : null}
                        {errorDetail && (
                            <p className='text-danger'>
                                Une erreur est survenue lors de la synchronisation<br />
                                {errorDetail}
                            </p>
                       )}
                    </ul>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={() => setCurrentError(null)}>
                        Fermer
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default MaintenancesList;
