export const isSignatureSigned = (signature: any): boolean => {
    if (signature === undefined || signature === null) {
        return false;
    }

    if (signature.done !== undefined) {
        return signature.done;
    }

    return (signature.content !== undefined && signature.content) || (signature.discharge !== undefined && signature.discharge);
}