import { FC } from "react";
import { useSelector } from "react-redux";
import { selectMaintenanceById } from "../redux/selectors";
import { RootState } from "../store";
import { useParams } from "react-router-dom";
import NavbarMenu from "../components/Navbar/Navbar.menu";
import { TitleLayout } from "../components/Styled/Title.Layout";
import Footer from "../components/Footer/Footer";
import VibrationControlsViewer from "../components/Viewer/Viewer.VibrationControls";
import MaterialChildrenStateViewer from "../components/Viewer/Viewer.MaterialChildrenState";
import SignatureViewer from "../components/Viewer/Viewer.Signature";
import { Container, Spinner } from "react-bootstrap";
import GalleryViewer from "../components/Viewer/Viewer.Gallery";
import GlobalDataHeaderViewer from "../components/Viewer/Viewer.GlobalDataHeader";
import ViewerExtraSpareParts from "../components/Viewer/Viewer.ExtraSpareParts";
import ViewerGreaseReplacementComment from "../components/Viewer/Viewer.GreaseReplacementComment";
import ViewerMaintenanceComment from "../components/Viewer/Viewer.MaintanceComment";

const MaintenanceViewer: FC = () => {
    const { id } = useParams();
    const state = useSelector<RootState, RootState>((state) => state);
    const maintenance = selectMaintenanceById(state, id);

    return (
        <>
            {maintenance ? (
                <TitleLayout>
                    <NavbarMenu navbarTitle='Détails de la maintenance' />
                    <h2 className='title'>
                        <u>Rapport de maintenance</u>
                    </h2>
                    <Container className='container-title-layout'>
                        <GlobalDataHeaderViewer maintenance={maintenance} />
                        <VibrationControlsViewer data={maintenance} />
                        <MaterialChildrenStateViewer maintenance={maintenance} />
                        <ViewerGreaseReplacementComment data={maintenance} />
                        <ViewerExtraSpareParts data={maintenance} />
                        <GalleryViewer data={maintenance} />
                        <ViewerMaintenanceComment data={maintenance} />
                        <hr className='w-100 my-5' />
                        <SignatureViewer data={maintenance} />
                    </Container>
                    <Footer type='back' />
                </TitleLayout>
            ) : (
                <TitleLayout>
                    <div
                        style={{ minHeight: "100vh" }}
                        className='d-flex flex-column justify-content-center align-items-center'>
                        <h2 className='p-5'>Veuillez patienter...</h2>
                        <Spinner className='p-5' animation='border' variant='primary' />
                    </div>
                </TitleLayout>
            )}
        </>
    );
};

export default MaintenanceViewer;
