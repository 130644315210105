import { materialStates } from "./material";

export const defineMaterialHierarchy = (maintenance: any) => {
    const { materialHierarchy, resolvedHierarchy, materialItems } = maintenance;
    let materialParentsMappingHierarchy: any[] = [];
    let materialChildrensMappingHierarchy: any[] = [];

    if (materialHierarchy && materialItems) {
        const materialHierarchyValues = Object.values(materialHierarchy)[0] as object;

        Object.entries(materialHierarchyValues).forEach((childHierarchy: any, index) => {
            for (const items of materialItems) {
                Object.entries(resolvedHierarchy.children).forEach((child: any) => {
                    if (child[1].material.id === +childHierarchy[0]) {
                        materialParentsMappingHierarchy[index] = child[1].material;
                    }
                });

                for (const childId of Object.keys(childHierarchy[1])) {
                    if (items.material.id === +childId) {
                        materialChildrensMappingHierarchy[index] = [
                            ...(materialChildrensMappingHierarchy[index] || []),
                            items,
                        ];
                    }
                }
            }
        });
    }

    return { materialParentsMappingHierarchy, materialChildrensMappingHierarchy };
};



export const defineVibrationControlsResultColor = (value: number|null): any => {
    if (value === null) {
        return { backgroundColor: "#fff" };
    }

    if (value < 5) {
        return { backgroundColor: "#9AE389" };
    }
    if (5 <= value && value < 10) {
        return { backgroundColor: "#6BA984" };
    }
    if (10 <= value && value < 15) {
        return { backgroundColor: "#FFF77F" };
    }
    if (15 <= value && value < 20) {
        return { backgroundColor: "#F5C489" };
    }
    if (20 <= value) {
        return { backgroundColor: "#EC8B97" };
    }
    return "#9AE389";
};
