import * as Yup from "yup";
import {
    ICheckpointModel,
    IInterventionCheckpointModel,
    IVibrationControlsModel,
} from "../interfaces/model";
import { getResourceId } from "../utils";
import vibrationControlsValidationSchema from "./vibrationControls.schema";

export const interventionAccessoryValidationScema = Yup.object().shape({
    state: Yup.string().required("Champs requis"),
    quantity: Yup.number().required("Champs requis"),
    accessory: Yup.lazy((v) => {
        return typeof v === "object"
            ? Yup.object().shape({
                  id: Yup.mixed().required("Champs requis"),
              })
            : Yup.mixed().required("Champs requis");
    }),
});

export const interventionValidationSchema = Yup.object().shape({
    nbHours: Yup.number()
        .typeError("Ce champ doit contenir un nombre")
        .integer("Ce champ doit être un entier")
        .notOneOf([0], "Ce champ doit être différent de 0")
        .required("Champ requis"),
    accessories: Yup.array().of(interventionAccessoryValidationScema),
    vibrationControlsBeforeAssembly: vibrationControlsValidationSchema,
    vibrationControlsAfterAssembly: vibrationControlsValidationSchema,
    vibrationControlsComment: Yup.string().nullable().when(
        ["vibrationControlsBeforeAssembly", "vibrationControlsAfterAssembly"],
        {
            is: (before: IVibrationControlsModel, after: IVibrationControlsModel) => {
                return before.cannotBeDone || after.cannotBeDone;
            },
            then: Yup.string().required(
                "Expliquez pourquoi vous n'avez pas effectué tous les contrôles"
            ),
            otherwise: Yup.string().nullable().notRequired(),
        }
    ),
    checkpointsComment: Yup.string().nullable().when(["$checkpoints", "checkpoints"], {
        is: (checkpoints: any, interventionCheckpoints: IInterventionCheckpointModel[]) => {
            if (checkpoints === null || checkpoints === undefined) {
                return true;
            }

            if (checkpoints.length === 0) {
                return false;
            }

            let allFound = true;

            checkpoints?.forEach((checkpoint: ICheckpointModel) => {
                let found = false;
                interventionCheckpoints?.forEach((ic) => {
                    if (getResourceId(ic.checkpoint) === checkpoint.id && ic.value === true) {
                        found = true;
                        return;
                    }
                });

                if (!found) {
                    allFound = false;
                    return;
                }
            });

            return !allFound;
        },
        then: Yup.string().required(
            "Expliquez pourquoi vous n'avez pas effectué tous les contrôles"
        ),
        otherwise: Yup.string().nullable().notRequired(),
    }),
});
