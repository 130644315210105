import {
    USER_LOGIN_FAILURE,
    USER_LOGIN_REQUEST,
    USER_LOGIN_SUCCESS,
    USER_LOGOUT,
} from "../reducer.types";

import { IUserState } from "../../interfaces/state";
import { IReduxAwareAction } from "../../interfaces/action";

// User Login Reducer
export const userLoginReducer = (
    state: IUserState = { userData: null },
    action: IReduxAwareAction
) => {
    switch (action.type) {
        case USER_LOGIN_REQUEST:
            return {
                loading: true,
                error: null,
                isLoggedIn: false,
                userData: {},
            };
        case USER_LOGIN_SUCCESS:
            return {
                loading: false,
                error: null,
                isLoggedIn: true,
                userData: action.payload,
            };
        case USER_LOGIN_FAILURE:
            return {
                loading: false,
                error: action.payload,
                isLoggedIn: false,
                userData: {},
            };
        case USER_LOGOUT:
            return {
                loading: false,
                error: null,
                isLoggedIn: false,
                userData: {},
            };
        default:
            return state;
    }
};
