import { FC } from "react";

import { useNavigate } from "react-router-dom";

import { Container, Button, Row, Col } from "react-bootstrap";
import { FooterLayout } from "../Styled/Footer.Layout";

const Footer: FC<{ type: string }> = ({ type }) => {
    const navigate = useNavigate();
    return (
        <FooterLayout style={type === "back" ? { height: "55px" } : { height: "100px" }}>
            {type === "back" && (
                <Container fluid>
                    <Row>
                        <Col className='text-center'>
                            <Button
                                variant='primary'
                                className='w-100'
                                onClick={() => navigate(-1)}>
                                Retour
                            </Button>
                        </Col>
                    </Row>
                </Container>
            )}
        </FooterLayout>
    );
};

export default Footer;
