// Redux Maintenances Types
export const MAINTENANCES_FETCH_REQUEST = "MAINTENANCES_FETCH_REQUEST";
export const MAINTENANCES_FETCH_SUCCESS = "MAINTENANCES_FETCH_SUCCESS";
export const MAINTENANCES_FETCH_FAILURE = "MAINTENANCES_FETCH_FAILURE";
export const MAINTENANCES_RESET = "MAITENANCES_RESET";
export const MAINTENANCES_API_REQUEST_START = "MAINTENANCES_API_REQUEST_START";
export const MAINTENANCES_POST_PATCH_INCREMENT_PROGRESS = "MAINTENANCES_POST_PATCH_INCREMENT_PROGRESS";
export const MAINTENANCES_POST_PATCH_SUCCESS = "MAINTENANCES_POST_PATCH_SUCCESS";
export const MAINTENANCES_API_OPERATION_FAILURE = "MAINTENANCES_API_OPERATION_FAILURE";
export const MAINTENANCES_POST_PATCH_RESET = "MAINTENANCES_POST_PATCH_RESET";
export const MAINTENANCES_UPDATE_OR_ADD = "MAINTENANCES_UPDATE_OR_ADD";
export const MAINTENANCES_DELETE_SUCCESS = "MAINTENANCES_DELETE_SUCCESS";
export const MAINTENANCES_LOADING_CANCEL = "MAINTENANCES_LOADING_CANCEL";
