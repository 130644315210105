import { CHECKPOINTS_FETCH_SUCCESS, CHECKPOINTS_RESET } from "../reducer.types";

import { ICheckpointsState } from "../../interfaces/state";
import { IReduxAwareAction } from "../../interfaces/action";

// Checkpoints Reducer
export const checkpointsReducer = (
    state: ICheckpointsState = { checkpoints: null },
    action: IReduxAwareAction
) => {
    switch (action.type) {
        case CHECKPOINTS_FETCH_SUCCESS:
            return {
                loading: false,
                error: null,
                checkpoints: action.payload,
            };

        case CHECKPOINTS_RESET:
            return {
                loading: false,
                error: null,
                checkpoints: null,
            };

        default:
            return state;
    }
};
