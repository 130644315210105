import { Field, useFormikContext } from "formik";
import { ChangeEvent, FC } from "react";
import { useSelector } from "react-redux";
import { ICheckpointModel, IInterventionModel } from "../../../interfaces/model";
import { selectTypedCheckpointList } from "../../../redux/selectors";
import { RootState } from "../../../store";
import { getResourceId } from "../../../utils";

const FieldCheckpointList: FC<{
    type: string;
}> = ({ type }) => {
    const state = useSelector<RootState, RootState>((state) => state);
    const checkpoints = selectTypedCheckpointList(state, type);

    const { values, setFieldValue } = useFormikContext();
    const intervention = values ? (values as IInterventionModel) : ({} as IInterventionModel);
    const checkpointIsChecked = (
        checkpoint: ICheckpointModel,
        intervention: IInterventionModel
    ) => {
        let checkpointValue = null;
        intervention.checkpoints?.forEach((c) => {
            if (getResourceId(c.checkpoint) === checkpoint.id) {
                checkpointValue = c.value;
            }
        });

        return checkpointValue !== null ? checkpointValue : false;
    };

    if (checkpoints.length === 0) {
        return <></>;
    }

    return (
        <>
            {checkpoints?.map((checkpoint) => (
                <div className='form-check' key={checkpoint.id}>
                    <Field
                        type='checkbox'
                        id={"checkpoint_value_" + checkpoint.id}
                        name='checkpoints[]'
                        checked={checkpointIsChecked(checkpoint, intervention)}
                        className='form-check-input'
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            let found = false;
                            const newCheckpoints = intervention.checkpoints
                                ? intervention.checkpoints.map((c) => {
                                      if (getResourceId(c.checkpoint) === checkpoint.id) {
                                          found = true;
                                          return { ...c, value: e.target.checked };
                                      }
                                      return c;
                                  })
                                : [];
                            if (!found) {
                                newCheckpoints.push({
                                    checkpoint: checkpoint,
                                    value: e.target.checked,
                                });
                            }

                            setFieldValue("checkpoints", newCheckpoints);
                        }}
                    />
                    <label
                        className='form-check-label'
                        htmlFor={"checkpoint_value_" + checkpoint.id}>
                        {checkpoint.name}
                    </label>
                </div>
            ))}
        </>
    );
};

export default FieldCheckpointList;
