import { ChangeEvent, FC } from "react";
import { Field, FieldArrayRenderProps, FormikValues } from "formik";
import { nanoid } from "nanoid";
import { Row, Card, Col } from "react-bootstrap";
import { PlusCircleFill, TrashFill } from "react-bootstrap-icons";
import FormError from "./Form/FormError";
import { IExtraSparePartModel } from "../interfaces/model/IExtraSparePartModel";

const ExtraSpareParts: FC<{
    arrayHelpers: FieldArrayRenderProps;
    values: FormikValues;
}> = ({ arrayHelpers, values }) => {
    return (
        <>
            <Row className='mb-3'>
                <Col className='d-flex align-items-center'>
                    <h6 className='flex-grow-1 m-0'>
                        Ajouter une pièce détachées hors contrat maintenance
                    </h6>
                    <PlusCircleFill
                        onClick={() => {
                            arrayHelpers.push({
                                name: "",
                                id: nanoid(),
                            });
                        }}
                        color='green'
                        className='mx-5 hover-icon'
                        size={30}
                    />
                </Col>
            </Row>
            {(values.extraSpareParts && values.extraSpareParts.length > 0) &&
                values.extraSpareParts.map((extraSparePart: IExtraSparePartModel, index: number) => (
                    <Row key={extraSparePart.id} className='mb-3'>
                        <Col>
                            <Card className='shadow-sm'>
                                <Card.Body className='d-flex align-items-center'>
                                    <Card.Title className='me-5 mb-0'>
                                        n°{index + 1}
                                    </Card.Title>
                                    {/* Change the border of field onChange*/}
                                    <Field
                                        as='input'
                                        name={`extraSpareParts.${index}.name`}
                                        value={extraSparePart.name}
                                        className={`m-0 form-control shadow-sm ${ extraSparePart.name === "" ? "border-danger" : "border-success"}`}
                                        onClick={(e: ChangeEvent<HTMLInputElement>) => {
                                            e.target.classList.remove("border-danger");
                                            e.target.classList.add("border-success");
                                        }}
                                        placeholder='Renseignez la désignation de la pièce'
                                    />
                                    <FormError path='extraSpareParts' />
                                    <TrashFill
                                        onClick={() => {arrayHelpers.remove(index)}}
                                        color='red'
                                        className='mx-5 hover-icon'
                                        size={32}
                                    />
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                )
            )}
        </>
    );
};

export default ExtraSpareParts;
