import { FC } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { logout } from "../../redux/actions";
import { Container, Navbar, Nav } from "react-bootstrap";

const NavbarMenu: FC<{ navbarTitle: string }> = ({ navbarTitle }) => {

    const dispatch = useDispatch();

    return (
        <Navbar fixed='top' bg='primary' variant='dark' expand='lg' className='shadow'>
            <Container>
                <Navbar.Brand className='text-light'>{navbarTitle}</Navbar.Brand>
                <Navbar.Toggle aria-controls='basic-navbar-nav' />
                <Navbar.Collapse id='basic-navbar-nav'>
                    <Nav className='ms-auto'>
                        <Link className='nav-link' to='/home'>
                            Accueil
                        </Link>
                        <Link className='nav-link' to='/maintenance/list'>
                            Liste des maintenances
                        </Link>
                        <Link className='nav-link' to='/rental/list'>
                            Location
                        </Link>
                        <Link
                            className='nav-link'
                            to='/scan'
                        >
                            Recherche matériel
                        </Link>
                        <Link
                            className='nav-link'
                            to='#'
                            onClick={() => {
                                dispatch(logout());
                            }}>
                            Déconnexion
                        </Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default NavbarMenu;
