import {
    USER_LOGIN_REQUEST,
    USER_LOGIN_SUCCESS,
    USER_LOGIN_FAILURE,
    USER_LOGOUT,
} from "../reducer.types";

import { apiUserLogin } from "../../config/config.api.userLogin";

import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { RootState } from "../../store";

import { IUserCredentialsModel } from "../../interfaces/model";

// Login User
export const login =
    (
        credentials: IUserCredentialsModel
    ): ThunkAction<Promise<void>, RootState, unknown, AnyAction> =>
    async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>): Promise<void> => {
        try {
            dispatch({
                type: USER_LOGIN_REQUEST,
            });

            const userData = await apiUserLogin(credentials);

            dispatch({
                type: USER_LOGIN_SUCCESS,
                payload: userData,
            });
        } catch (error: any) {
            dispatch({
                type: USER_LOGIN_FAILURE,
                payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
            });
        }
    };

// Logout User
export const logout =
    (): ThunkAction<Promise<void>, RootState, unknown, AnyAction> =>
    async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>): Promise<void> => {
        try {
            dispatch({
                type: USER_LOGOUT,
            });
        } catch (error: any) {
            console.error(error);
        }
    };
