import { apiBaseUrl } from "./config.api.baseUrl";

import { IUserCredentialsModel } from "../interfaces/model";

// Create Post Request for User Login
export const apiUserLogin = async (credentials: IUserCredentialsModel) => {
    const response = await apiBaseUrl().post("/api/login_check", {
        username: credentials.username,
        password: credentials.password,
    });

    return response.data;
};
