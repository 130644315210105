import { FC, useEffect, useState, SyntheticEvent } from "react";

import { ModalContainer } from "../Styled/Modal.Container";
import { Button, Modal } from "react-bootstrap";

const ModalErrorNetwork: FC = () => {
  // Handle Network State
  const [handleNetworkState, setHandleNetworkState] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    navigator.onLine
      ? setHandleNetworkState(false)
      : setHandleNetworkState(true);
  }, []);

  const handleCheckNetworkState = (event: SyntheticEvent) => {
    event.preventDefault();
    setIsUpdating(true);
    if (navigator.onLine) {
      setTimeout(() => {
        setHandleNetworkState(false);
        setIsUpdating(false);
      }, 2000);
    } else {
      setTimeout(() => {
        setHandleNetworkState(true);
        setIsUpdating(false);
      }, 2000);
    }
  };

  return (
    <>
      {handleNetworkState && (
        <ModalContainer>
          <Modal.Dialog>
            <Modal.Header>
              <Modal.Title>
                <h3>Vous n'êtes pas connecté à internet</h3>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                Vous devez être connecté à internet pour accéder à
                l'application.
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant={isUpdating ? "warning" : "primary"}
                className="text-white"
                onClick={handleCheckNetworkState}
              >
                {isUpdating ? "Vérification en cours..." : "Réessayer"}
              </Button>
            </Modal.Footer>
          </Modal.Dialog>
        </ModalContainer>
      )}
    </>
  );
};

export default ModalErrorNetwork;
