import React, { cloneElement, FC } from "react";
import { useFormikContext } from "formik";
import FormError, { fieldErrors, showError } from "./FormError";
import { formNameToId } from "../../utils";

export interface IFormRow extends React.HTMLAttributes<HTMLDivElement> {
    label?: string | boolean;
    name: string;
    children: any;
    errorProps?: any;
    labelProps?: any;
}

const FormRow: FC<IFormRow> = ({ label, children, name, errorProps, labelProps, ...props }) => {
    const { errors, touched, validateOnBlur, validateOnChange, submitCount } = useFormikContext();
    const childrenProps = {
        name,
        id: formNameToId(name),
        ...children.props
    };
    let errorPropsC = errorProps !== undefined ? { ...errorProps } : {};

    const errorsResult = fieldErrors(errors, name);
    const inputType = childrenProps.type;
    const showErrorResult = showError(
        touched,
        name,
        validateOnBlur,
        validateOnChange,
        submitCount,
        errorPropsC.show
    );

    if (errorsResult && showErrorResult) {
        childrenProps.className += " is-invalid";
    }

    props.className = props.className ?? 'mb-3'

    if (inputType === 'checkbox') {
        props.className += ' form-check';
    }

    //configure labelProps
    labelProps = labelProps ?? {};
    labelProps.htmlFor = childrenProps.id;
    if (inputType === 'checkbox') {
        labelProps.className = labelProps.className ?? 'form-check-label';
    }
    else {
        labelProps.className = labelProps.className ?? 'form-label my-2';
    }
    labelProps.className += childrenProps.required ? ' required' : '';

    const innerElement = (
        <>
            {cloneElement(children, childrenProps)}
            <FormError path={name} {...errorPropsC} />
        </>
    );

    return (
        <div {...props}>
            {label && (
                <label {...labelProps}>
                    <b>{label}</b>
                </label>
            )}

            {inputType === 'checkbox' ? (
                innerElement
            ) : (
                <div className='input-group has-validation'>
                    {innerElement}
                </div>
            )}
        </div>
    );
};

export default FormRow;
