import { FC, useEffect, useState } from "react";
import { selectUserToken } from "../../redux/selectors";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { IMaintenancesModel } from "../../interfaces/model";
import { defineMaterialHierarchy, createMaterialHierarchy } from "../../utils";
import ListGroupViewer from "./List/Viewer.ListGroup";
import { apiGetMaterialByMaterialReference } from "../../config/config.api.material";
import { Col, Row } from "react-bootstrap";

const MaterialChildrenStateViewer: FC<{ maintenance: IMaintenancesModel }> = ({ maintenance }) => {
    const state = useSelector<RootState, RootState>((state) => state);
    const userToken = selectUserToken(state);
    const [parentsMappingHierarchy, setParentsMappingHierarchy] = useState<any[]>();
    const [childrensMappingHierarchy, setChildrensMappingHierarchy] = useState<any[]>();

    useEffect(() => {
        const { materialParentsMappingHierarchy, materialChildrensMappingHierarchy } = defineMaterialHierarchy(maintenance);
        if (
            materialParentsMappingHierarchy.length === 0 &&
            materialChildrensMappingHierarchy.length === 0
        ) {
            const handleFetchMaterial = async () => {
                try {
                    if (maintenance.material && userToken) {
                        const material = await apiGetMaterialByMaterialReference(
                            userToken,
                            maintenance.material?.reference
                        );
                        
                        const {
                            materialParentsMappingHierarchy,
                            materialChildrensMappingHierarchy,
                        } = createMaterialHierarchy(material[0], material[0].children);

                        setParentsMappingHierarchy(materialParentsMappingHierarchy);
                        setChildrensMappingHierarchy(materialChildrensMappingHierarchy);
                    }
                } catch (error) {
                    console.error(error);
                }
            };

            handleFetchMaterial();
        } else {
            setParentsMappingHierarchy(materialParentsMappingHierarchy);
            setChildrensMappingHierarchy(materialChildrensMappingHierarchy);
        }
    }, [maintenance, userToken]);

    return (
        <>
            {maintenance.materialItems && maintenance.materialItems.length > 0 && (
                <>
                    <hr className='w-100 my-5' />
                    <Row className='mb-3'>
                        <Col>
                            <h5 className='text-center'>
                                <u>
                                    <b>Etats des pièces controlées</b>
                                </u>
                            </h5>
                        </Col>
                    </Row>
                    {parentsMappingHierarchy && childrensMappingHierarchy && (
                        <ListGroupViewer
                            maintenance={maintenance}
                            materialParentsMappingHierarchy={parentsMappingHierarchy}
                            materialChildrensMappingHierarchy={childrensMappingHierarchy}
                        />
                    )}
                    {maintenance.materialItemsComment && (
                        <>
                            <h6>
                                <u>
                                    <b>Commentaires contrôles des pièces :</b>
                                </u>
                            </h6>
                            <p>{maintenance.materialItemsComment}</p>
                        </>
                    )}
                </>
            )}
        </>
    );
};

export default MaterialChildrenStateViewer;
