import { FC, useMemo } from "react";
import { IImagesModel, IMaintenanceMaterialItemModel, IMaintenancesModel, IMaterialModel, IVibrationControlsModel } from "../../../interfaces/model";
import { Field, Form, Formik, useFormikContext } from "formik";
import { Button, Card, Col, Row } from "react-bootstrap";
import PromptIfDirty from "../../PromptIfDirty";
import FormRow from "../FormRow";
import FieldMaterialState from "../FieldMaterialState";
import FieldMaintenanceInterventionType from "../FieldMaintenanceInterventionType";
import { getResourceId, getVisibleChildrenForMaintenanceNbHours, maintenanceInterventionTypes, materialChildrenIds } from "../../../utils";
import FieldVibrationControls from "../FieldVibrationControls";
import FieldGallery from "../FieldGallery";
import { maintenanceEditChildMaterialValidationSchema } from "../../../validation/maintenance.edit.child.material.schema";

export interface IEditChildMaterialProps {
    material: IMaterialModel,
    handleSubmit: (values: any) => void,
}

const EditChildMaterial: FC<IEditChildMaterialProps> = ({ material, handleSubmit, ...props }: IEditChildMaterialProps) => {

    const { values } = useFormikContext();
    const maintenance = values as IMaintenancesModel;
    const materialIds = materialChildrenIds(material, true);
    const visibleChildren = useMemo(() => getVisibleChildrenForMaintenanceNbHours(material, maintenance.maintenanceNbHours), [material, maintenance]);

    //Transform values from maintenance to excepted format on formik
    const transformValues = () => {
        //Intervention type
        let interventionType = maintenanceInterventionTypes.maintenance;
        maintenance.materialItems?.forEach((item) => {
            if (getResourceId(item.material) === getResourceId(material)) {
                interventionType = item.interventionType;
                return;
            }
        });

        //Material items
        const materialItems = visibleChildren.map((childMaterial: IMaterialModel) => {
            let item = maintenance.materialItems?.find((item) => {
                return getResourceId(item.material) === getResourceId(childMaterial);
            });

            if (item) {
                item = {
                    ...item,
                    images: maintenance.gallery?.images?.filter((image) => {
                        return image.options?.materialId === getResourceId(childMaterial);
                    }),
                }
            } else {
                item = {
                    state: null,
                    interventionType: null,
                    material: childMaterial,
                    images: [],
                } as IMaintenanceMaterialItemModel;
            }

            return item;
        });

        materialItems.push({
            state: null,
            interventionType: null,
            material,
            images: [],
        } as IMaintenanceMaterialItemModel);

        return {
            centrifuge: material.centrifuge === true,
            materialItems,
            interventionType,
            vibrationControlsBeforeAssembly: maintenance.vibrationControlsBeforeAssembly as IVibrationControlsModel,
            vibrationControlsAfterAssembly: maintenance.vibrationControlsAfterAssembly as IVibrationControlsModel,
            vibrationControlsComment: maintenance.vibrationControlsComment,
        };
    }

    //Transform values from formik values to maintenance
    const reverseTransformValues = (values: any) => {
        //Reset material items
        const filtredItems = maintenance.materialItems ? maintenance.materialItems.filter((item) => {
            return !materialIds.includes(getResourceId(item.material));
        }) : [];

        const valuesItems = values.materialItems.map((item: IMaintenanceMaterialItemModel) => ({
            ...item,
            interventionType: values.interventionType,
        }));


        const materialItems = [...filtredItems, ...valuesItems];

        //Reset images
        const images = maintenance.gallery?.images ? maintenance.gallery.images.filter((image) => {
            const materialId = image?.options?.materialId;
            return materialId && !materialIds.includes(materialId);
        }) : [];

        valuesItems.forEach((item: any) => {
            const materialId = getResourceId(item.material);
            if (!item.images) return;

            images.push(...item.images.map((image: IImagesModel) => {
                return {
                    ...image,
                    options: {
                        ...image.options,
                        materialId,
                    }
                }
            }));
        });

        return {
            ...values,
            materialItems,
            gallery: {
                ...maintenance.gallery,
                images,
            },
        }
    }

    return (
        <>
            <Formik
                validationSchema={maintenanceEditChildMaterialValidationSchema}
                initialValues={transformValues()}
                onSubmit={values => handleSubmit(reverseTransformValues(values))}
            >
                <Form>
                    <PromptIfDirty />
                    {visibleChildren.map((childMaterial: IMaterialModel, index: number) => (
                        <Row key={childMaterial.id} className='form-group d-flex align-items-center mb-3'>
                            <Col>
                                <Card>
                                    <Card.Body className='d-flex flex-column'>
                                        <h5 className='flex-grow-1 m-0'>
                                            {childMaterial.name}
                                        </h5>
                                        <div>
                                            <FormRow name={`materialItems[${index}].state`} label={false}>
                                                <FieldMaterialState nullable={false} />
                                            </FormRow>
                                            {childMaterial.greaseControl && (
                                                <div className='d-flex align-items-center mt-3'>
                                                    <FormRow label="Remplacement de graisses" name={`materialItems[${index}].greaseReplacement`}>
                                                        <Field
                                                            className="form-check-input mt-0 me-3 "
                                                            type='checkbox'
                                                            name={`materialItems[${index}].isGreaseReplacement`}

                                                        />
                                                    </FormRow>
                                                </div>
                                            )}
                                        </div>
                                        <div>
                                            <hr />
                                            <FieldGallery name={`materialItems[${index}].images`} commentField={false} />
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    ))}
                    <FormRow label='Type de maintenance' name="interventionType">
                        <FieldMaintenanceInterventionType />
                    </FormRow>
                    {material.centrifuge && (
                        <>
                            <h5 className='mt-4'>Contrôles vibratoires</h5>
                            <FieldVibrationControls />
                        </>
                    )}
                    <Button
                        variant='primary w-100'
                        type='submit'
                    >
                        Valider
                    </Button>
                </Form>
            </Formik>
        </>
    )
}

export default EditChildMaterial;