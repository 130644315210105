import { FC } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { INotFound } from "../interfaces/component.props/INotFound";
import { BaseLayout } from "./Styled/Base.Layout";
import { LogoContainer } from "./Styled/Logo.Container";
import Logo from "../assets/img/Logo.png";

const NotFound: FC<INotFound> = ({ title, message, backButtonContent, closeCallback }) => {
    const navigate = useNavigate();

    return (
        <BaseLayout className="container">
            <h1>{title ?? "Page introuvable"}</h1>
            <p className='my-5'>{message ?? "La page demandée n'existe pas"}</p>
            <Button
                variant='primary'
                onClick={() => {
                    closeCallback ?? navigate("/home");
                }}>
                {backButtonContent ?? "Retour à l'accueil"}
            </Button>
            <LogoContainer src={Logo} alt='logo LCMI' />
        </BaseLayout>
    );
};

export default NotFound;
