import { RootState } from "../../store";
import { createSelector } from "@reduxjs/toolkit";
import { ICheckpointsState } from "../../interfaces/state";

const selectCheckpoints = (state: RootState): ICheckpointsState => state.checkpoints;
const selectType = (_: RootState, type: string) => type;


export const selectCheckpointList = createSelector([selectCheckpoints], (checkpoints) => {
    return checkpoints.checkpoints ?? []
});

export const selectTypedCheckpointList = createSelector([selectCheckpointList, selectType], (checkpoints, type) => {
    return checkpoints.filter(c => c.interventionTypes.includes(type));
});