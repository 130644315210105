import { Field } from "formik";
import React, { FC } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { selectCountriesList } from "../../redux/selectors";

const FieldCountry: FC<React.HTMLAttributes<HTMLElement>> = (props) => {
    const state = useSelector<RootState, RootState>((state) => state);
    const countries = selectCountriesList(state);

    return (
        <Field
            as='select'
            type='text'
            {...props}
        >
            <option value=''>Sélectionnez un pays</option>
            {countries.map((country) => (
                <option value={country.code} key={country.code}>
                    {country.name}
                </option>
            ))}
        </Field>);
}

export default FieldCountry;