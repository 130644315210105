import { RootState } from "../../store";
import { createSelector } from "@reduxjs/toolkit";
import { IInterventionsState } from "../../interfaces/state";
import { isSyncable } from "../../utils";

const selectInterventions = (state: RootState): IInterventionsState => state.interventions;
const selectInterventionId = (_: any, interventionId: string | undefined): string | undefined => interventionId;

export const selectInterventionsList = createSelector([selectInterventions], (interventions) => {
    if (interventions.interventions) {
        return interventions.interventions.length > 0 ? interventions.interventions : [];
    } else {
        return [];
    }
});

export const selectInterventionById = createSelector(
    [selectInterventions, selectInterventionId],
    (interventions, interventionId) => {
        const intervention = interventions.interventions?.find((intervention) => intervention.id == interventionId); // "==" id can be a string or an int 
        return intervention ? intervention : null;
    }
);


export const selectInterventionToSync = createSelector(
    [selectInterventions],
    (interventions) => interventions.interventions?.filter((intervention) => isSyncable(intervention))
);