import { apiBaseUrl } from "./config.api.baseUrl";

import { IMaterialModel } from "../interfaces/model";

export const baseRentalIri = "/api/rentals";


// Create Get Request for the last Rental Intervention by Material ID
export const apiGetLastRentalInterventionByMaterialId = async (
  userToken: string,
  materialId: IMaterialModel["id"]
) => {
  const response = await apiBaseUrl(userToken).get(
    `/api/rentals?order%5BstartDate%5D=desc&material.id=${materialId}`
  );
  return response.data;
};
