import { FC, useState } from "react";
import { Col, Row, Toast, ToastProps } from "react-bootstrap";

interface IToastErrorProps extends ToastProps {
    toastTitle: string;
    toastMessage: string;
}

const ToastError: FC<IToastErrorProps> = ({ toastTitle, toastMessage, ...props }) => {
    const [show, setShow] = useState(true);
    return (
        <Row>
            <Col>
                <Toast
                    style={{ minWidth: "100%" }}
                    show={show}
                    onClose={() => setShow(false)}
                    {...props}>
                    <Toast.Header>
                        <strong className='me-auto'>Erreur</strong>
                        <small>{toastTitle}</small>
                    </Toast.Header>
                    <Toast.Body>
                        <p>{toastMessage}</p>
                    </Toast.Body>
                </Toast>
            </Col>
        </Row>
    );
};

export default ToastError;
