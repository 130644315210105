import { FC } from "react";
import { formNameToId, maintenanceInterventionTypeLabel, maintenanceInterventionTypes } from "../../utils";
import { Field } from "formik";

const FieldMaintenanceInterventionType: FC<{ name?: string }> = ({ name }) => {

    const interventionTypes = [];
    for (let key in maintenanceInterventionTypes) {
        interventionTypes.push(key);
    }
    
    return (
        <div className='d-flex w-100'>
            {interventionTypes.map(interventionType => {
                const id = formNameToId(name!) + '_' + interventionType;
                return (
                    <div
                        className='d-flex justify-content-center align-items-center mx-2'
                        key={interventionType}
                    >
                        <Field
                            type='radio'
                            className={'form-check-input me-2 mt-0'}
                            name={name}
                            id={id}
                            value={interventionType}
                        />
                        <label className='form-check-label' htmlFor={id}>
                            {maintenanceInterventionTypeLabel(interventionType)}
                        </label>
                    </div>
                )
            })}
        </div>
    );
}

export default FieldMaintenanceInterventionType;