import {
    MAINTENANCES_FETCH_REQUEST,
    MAINTENANCES_FETCH_SUCCESS,
    MAINTENANCES_FETCH_FAILURE,
    MAINTENANCES_RESET,
    MAINTENANCES_API_REQUEST_START,
    MAINTENANCES_POST_PATCH_INCREMENT_PROGRESS,
    MAINTENANCES_POST_PATCH_SUCCESS,
    MAINTENANCES_API_OPERATION_FAILURE,
    MAINTENANCES_POST_PATCH_RESET,
    MAINTENANCES_UPDATE_OR_ADD,
    MAINTENANCES_DELETE_SUCCESS,
    MAINTENANCES_LOADING_CANCEL,
} from "../reducer.types";

import { IMaintenancesState } from "../../interfaces/state";
import { IReduxAwareAction } from "../../interfaces/action";
import { syncStates } from "../../utils";

// Maintenances Reducer
export const maintenancesReducer = (
    state: IMaintenancesState = { maintenances: null },
    action: IReduxAwareAction
) => {
    switch (action.type) {
        case MAINTENANCES_LOADING_CANCEL:
            return {
                ...state,
                loading: false,
            };
        case MAINTENANCES_FETCH_REQUEST:
            return {
                loading: true,
                error: null,
                maintenances: [],
                progressApiCall: 0,
            };
        case MAINTENANCES_FETCH_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                maintenances: action.payload,
            };
        case MAINTENANCES_FETCH_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
                maintenances: null,
            };
        case MAINTENANCES_RESET:
            return {
                loading: false,
                error: null,
                maintenances: null,
            };
        case MAINTENANCES_API_REQUEST_START:
            return {
                ...state,
                progressApiCall: 0,
                loading: true,
                error: null,
            };
        case MAINTENANCES_POST_PATCH_INCREMENT_PROGRESS:
            return {
                ...state,
                loading: action.payload !== 100 ? true : false,
                progressApiCall: action.payload,
            };
        case MAINTENANCES_POST_PATCH_SUCCESS:
            return {
                ...state,
                error: null,
                maintenances: state.maintenances?.map((maintenance, index) => {
                    const data =
                        index === action.payload.index ? action.payload.promiseData : maintenance;
                    return { ...data, syncState: "success", error: null };
                }),
            };
        case MAINTENANCES_API_OPERATION_FAILURE:
            return {
                ...state,
                loading: 
                    action.payload.loading !== undefined ? action.payload.loading : state.loading,
                maintenances: state.maintenances?.map((maintenance, index) => {
                    return index === action.payload.index
                        ? {
                              ...maintenance,
                              error: action.payload.error,
                              syncState: syncStates.failed,
                          }
                        : maintenance;
                }),
            };
        case MAINTENANCES_POST_PATCH_RESET:
            return {
                loading: false,
                error: null,
                maintenances: null,
            };
        case MAINTENANCES_UPDATE_OR_ADD:
            const maintenance = action.payload;
            let found = false;
            let maintenances = state.maintenances
                ? state.maintenances.map((m) => {
                      if (m.id === maintenance.id) {
                          found = true;
                          return maintenance;
                      }

                      return m;
                  })
                : [];

            if (!found) {
                maintenances = [maintenance, ...maintenances];
            }

            return {
                ...state,
                maintenances,
            };

        case MAINTENANCES_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                maintenances: state.maintenances?.filter((_, index) => {
                    return index !== action.payload.index;
                }),
            };

        default:
            return state;
    }
};
