import { FC } from "react";

import { useSelector } from "react-redux";
import { RootState } from "../store";

import { IUserState } from "../interfaces/state";

import { Navigate, Outlet } from "react-router-dom";

const useAuth = () => {
    const user = useSelector<RootState, IUserState>((state) => state.userLogin);
    return user?.isLoggedIn;
};

const AuthProvider: FC = () => {
    const isAuth = useAuth();
    return isAuth ? <Outlet /> : <Navigate to='/' />;
};

export default AuthProvider;
