import { FC, useRef, useState } from "react";
import Compressor from "compressorjs";
import { Field, FieldArray, useFormikContext } from "formik";
import FormRow from "./FormRow";
import { IGalleryAware, IImagesModel } from "../../interfaces/model";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { Images, X } from "react-bootstrap-icons";
import ToastError from "../ToastError";
import { ImageErrorLayout } from "../Styled/Capture.Camera.Modal";
import { getValueAtPath } from "../../utils";

const FieldGallery: FC<{ name?: string, commentField?: boolean }> = ({ name = 'gallery.images', commentField = true }) => {
    const { REACT_APP_API_BASE_URL } = process.env;
    const { values } = useFormikContext();
    const [inputPhotoErrors, setInputPhotoErrors] = useState<string[]>([]);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const container = values ? (values as IGalleryAware) : ({} as IGalleryAware);
    const images: IImagesModel[] = getValueAtPath(container, name) as IImagesModel[] ?? [] as IImagesModel[];

    const getBase64 = (file: File): Promise<string> => {
        return new Promise((resolve, reject) => {
            new Compressor(file, {
                quality: 0.8,
                success(result) {
                    const reader = new FileReader();
                    reader.readAsDataURL(result);
                    reader.onload = () => {
                        if (reader.result) {
                            const base64 = reader.result.toString();
                            resolve(base64);
                        }
                    };
                },
                error(err) {
                    reject(err);
                },
            });
        });
    };

    return (
        <>
            <FieldArray
                name={name}
                render={(arrayHelper) => (
                    <>
                        <Row>
                            {images.map((image, index) => (
                                <Col
                                    xs={12}
                                    sm={4}
                                    md={4}
                                    key={index}
                                    className='my-3'
                                    style={{ height: 300 }}>
                                    <div className='h-100 w-100 bg-light border border-light overflow-hidden position-relative d-flex justify-content-center align-items-center'>
                                        <img
                                            className='img-fluid'
                                            src={
                                                image.base64 ??
                                                REACT_APP_API_BASE_URL + image.contentUrl!
                                            }
                                            alt='LCMI'
                                        />
                                        <Button
                                            className='position-absolute top-0 end-0 mt-2 me-2 rounded-circle border border-white'
                                            variant='danger'
                                            style={{
                                                width: 30,
                                                height: 30,
                                                padding: 5,
                                                paddingTop: 0,
                                            }}
                                            onClick={() => arrayHelper.remove(index)}>
                                            <X />
                                        </Button>
                                        <div className='position-absolute bottom-0'>
                                            <FormRow name={`${name}[${index}].comment`}>
                                                <Field
                                                    as='textarea'
                                                    rows={2}
                                                    placeholder="Commentaire facultatif"
                                                    className="form-control textarea-resize"
                                                />
                                            </FormRow>
                                        </div>
                                        {image.error && (
                                            <ImageErrorLayout>
                                                <p className='text-danger text-center p-2'>
                                                    {image.error}
                                                </p>
                                                <Button
                                                    variant='danger text-white'
                                                    size='sm'
                                                    onClick={() => arrayHelper.remove(index)}>
                                                    Supprimer
                                                </Button>
                                            </ImageErrorLayout>
                                        )}
                                    </div>

                                    <Field
                                        name={`${name}[${index}].base64`}
                                        className='d-none'
                                    />
                                </Col>
                            ))}
                        </Row>
                        <Button
                            variant='outline-dark'
                            size='sm'
                            disabled={loading}
                            onClick={() => {
                                fileInputRef && fileInputRef.current?.click();
                            }}>
                            {loading ? (
                                <Spinner
                                    className='me-5'
                                    as='span'
                                    animation='border'
                                    size='sm'
                                    role='status'
                                    aria-hidden='true'
                                />
                            ) : <Images /> }
                            Ajouter une photo
                        </Button>
                        <div className='d-none'>
                            <input
                                type='file'
                                accept="image/*"
                                multiple
                                ref={fileInputRef}
                                onChange={async (e) => {
                                    setLoading(true);
                                    const files = e.target.files ?? [];
                                    for (let i = 0; i < files.length; i++) {
                                        try {
                                            const base64 = await getBase64(files[i]);
                                            arrayHelper.push({ base64 });
                                        } catch (err) {
                                            setInputPhotoErrors((prevErrors) => [
                                                ...prevErrors,
                                                files[i].name,
                                            ]);
                                        }
                                    }
                                    setLoading(false);
                                }}
                            />
                        </div>
                    </>
                )}
            />
            {inputPhotoErrors.length > 0 &&
                inputPhotoErrors.map((fileError, index) => (
                    <ToastError
                        key={index}
                        toastTitle="Erreur lors de l'ajout de la photo"
                        toastMessage={`La photo "${fileError}" n'a pas pu être ajoutée`}
                        bg='warning'
                        className='my-3'
                    />
                ))}
            {commentField && (
                <FormRow name='gallery.comment' label='Commentaire photos'>
                    <Field
                        as='textarea'
                        rows={5}
                        id='galleryComment'
                        className='form-control textarea-resize'
                    />
                </FormRow>
            )}
        </>
    );
};

export default FieldGallery;
