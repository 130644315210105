import { FC, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import { login } from "../redux/actions";

import { useNavigate } from "react-router-dom";

import { IUserCredentialsModel } from "../interfaces/model";
import { IUserState } from "../interfaces/state";

import { Formik, Form, Field } from "formik";

import { validationLoginSchema } from "../validation/login.schema";
import { INIT_VALUES_LOGIN } from "../constants/initial.values.login";

import NavbarTitle from "../components/Navbar/Navbar.title";
import ModalConfirmation from "../components/Modal/Modal.confirmation";
import ModalErrorNetwork from "../components/Modal/Modal.error.network";
import FormError from "../components/Form/FormError";

import Logo from "../assets/img/Logo.png";
import { LogoContainer } from "../components/Styled/Logo.Container";
import { BaseLayout } from "../components/Styled/Base.Layout";
import { Button } from "react-bootstrap";
import { EyeFill, EyeSlashFill, At } from "react-bootstrap-icons";

// Resolve env variables
const { REACT_APP_API_BASE_URL } = process.env;

const Login: FC = () => {
    // Handle Loading Network Login
    const user = useSelector<RootState, IUserState>((state) => state.userLogin);
    const { loading, error } = user;

    // Handle Login
    const { isLoggedIn } = user;
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleLogin = (formCredentials: IUserCredentialsModel) => {
        dispatch(login(formCredentials));
    };

    useEffect(() => {
        if (!loading && isLoggedIn) {
            navigate("/scan");
        }
    }, [loading, isLoggedIn, navigate]);

    // Handle Error Login
    const [showModalConfirmation, setShowModalConfirmation] = useState<boolean>(false);
    useEffect(() => {
        error && setShowModalConfirmation(true);
    }, [error]);

    // Handle Show Password
    const [showPassword, setShowPassword] = useState(false);

    return (
        <BaseLayout>
            <NavbarTitle title='CONNEXION COMPTE' />
            <Formik
                initialValues={INIT_VALUES_LOGIN}
                validationSchema={validationLoginSchema}
                onSubmit={handleLogin}>
                {({ errors, touched }) => (
                    <Form className='d-flex flex-column justify-content-center'>
                        <div className='form-group required'>
                            <label htmlFor='username' className='mb-2'>
                                Utilisateur
                            </label>
                            <div className='input-group mb-4'>
                                <Field
                                    type='text'
                                    name='username'
                                    className='form-control shadow-sm'
                                    id='username'
                                    placeholder='tech'
                                />
                                <div className='input-group-text'>
                                    <At />
                                </div>
                            </div>
                            <FormError path='username' />
                        </div>
                        <div className='form-group required'>
                            <label htmlFor='password' className='mb-2'>
                                Mot de Passe
                            </label>
                            <div className='input-group'>
                                <Field
                                    type={showPassword ? "text" : "password"}
                                    name='password'
                                    className='form-control shadow-sm'
                                    id='password'
                                />
                                <div className='input-group-text'>
                                    {showPassword ? (
                                        <EyeSlashFill
                                            onClick={() => setShowPassword(!showPassword)}
                                        />
                                    ) : (
                                        <EyeFill onClick={() => setShowPassword(!showPassword)} />
                                    )}
                                </div>
                            </div>
                            <FormError path='password' />
                        </div>
                        <div className='form-group mt-2 mb-5'>
                            <a
                                href={`${REACT_APP_API_BASE_URL}/reset-password`}
                                target='_blank'
                                rel='noreferrer'>
                                Mot de passe oublié ?
                            </a>
                        </div>
                        <Button
                            variant={loading ? "warning" : "primary"}
                            className='text-white'
                            type='submit'
                            disabled={loading}>
                            {loading ? "Connexion..." : "Se connecter"}
                        </Button>
                    </Form>
                )}
            </Formik>
            <LogoContainer src={Logo} alt='logo LCMI' />
            <ModalConfirmation
                title='Erreur'
                textContent="Votre nom d'utilisateur ou votre mot de passe est incorrect ! Veuillez réessayer."
                show={showModalConfirmation}
                closeCallBack={setShowModalConfirmation}
            />
            <ModalErrorNetwork />
        </BaseLayout>
    );
};

export default Login;
