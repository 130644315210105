import { FC } from "react";
import { Col, Row } from "react-bootstrap";
import { IMaintenancesModel } from "../../interfaces/model";
import { nanoid } from "nanoid";

const { REACT_APP_API_BASE_URL } = process.env;

const GalleryViewer: FC<{ data: IMaintenancesModel }> = ({ data }) => {
    return (
        <>
            {data.gallery?.images && data.gallery.images.length ? (
                <>
                    <hr className='w-100 my-5' />
                    <Row>
                        <Col>
                            <h6>
                                <u>
                                    <b>Photos :</b>
                                </u>
                            </h6>
                        </Col>
                    </Row>
                    <Row>
                        {data.gallery.images.map((photo) => (
                            <Col 
                                xs={12}
                                sm={6}
                                md={4}
                                className='my-3'
                                key={photo.contentUrl ? photo.id : nanoid()}
                                style={{ height: 300 }}
                            >
                                <div className='h-100 w-100 overflow-hidden position-relative d-flex flex-column justify-content-center align-items-center'>
                                    <img
                                        src={
                                            photo.contentUrl
                                            ? `${REACT_APP_API_BASE_URL}${photo.contentUrl}`
                                            : photo.base64
                                        }
                                        style={{ width: "200px", height: "auto" }}
                                        alt={`Intervention n° ${data.id}`}
                                        className='img-fluid m-3'
                                        />
                                    <p><strong>Commentaires :</strong></p>
                                    {photo.comment && <p>{photo.comment}</p>}
                                    {photo.error && <p className='text-danger'>{photo.error}</p>}
                                </div>
                            </Col>
                        ))}
                    </Row>
                </>
            ) : null}
        </>
    );
};

export default GalleryViewer;
