// Utility Function to format date
export const dateTransformer = (date: string | Date | undefined) => {
  const dateValidationRegex = /^\d{2}\/\d{2}\/\d{4}$/;

  if (date) {
    if (typeof date === "string" && dateValidationRegex.test(date)) {
      return date;
    } else {
      const newDate = new Date(date);
      const dateFormatted = newDate.toLocaleDateString("fr-FR");
      return dateFormatted;
    }
  }
};