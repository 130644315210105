import { FC } from "react";
import { Col, Row } from "react-bootstrap";
import { IMaintenancesModel } from "../../interfaces/model";

const ViewerMaintenanceComment: FC<{ data: IMaintenancesModel }> = ({ data }) => {
    return (
        <>
            {data.comment && (
                <>
                    <hr className='w-100 my-5' />
                    <Row>
                        <Col>
                            <h6>
                                <u>
                                    <b>Commentaires :</b>
                                </u>
                            </h6>
                            <p>{data.comment}</p>
                        </Col>
                    </Row>
                </>
            )}
        </>
    );
};

export default ViewerMaintenanceComment;
