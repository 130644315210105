import {
    INTERVENTIONS_FETCH_REQUEST,
    INTERVENTIONS_FETCH_SUCCESS,
    INTERVENTIONS_FETCH_FAILURE,
    INTERVENTIONS_RESET,
    INTERVENTIONS_UPDATE_OR_ADD,
    INTERVENTIONS_API_REQUEST_START,
    INTERVENTIONS_POST_PATCH_UPDATE_PROGRESS,
    INTERVENTIONS_POST_PATCH_SUCCESS,
    INTERVENTIONS_API_OPERATION_FAILURE,
    INTERVENTIONS_POST_PATCH_RESET,
    INTERVENTIONS_DELETE_SUCCESS,
    INTERVENTIONS_LOADING_CANCEL
} from "../reducer.types";

import { IInterventionsState } from "../../interfaces/state";
import { IReduxAwareAction } from "../../interfaces/action";
import { syncStates } from "../../utils";

// Interventions Reducer
export const interventionsReducer = (
    state: IInterventionsState = { interventions: null },
    action: IReduxAwareAction
) => {
    switch (action.type) {
        case INTERVENTIONS_LOADING_CANCEL:
            return {
                ...state,
                loading: false,
            };
        case INTERVENTIONS_FETCH_REQUEST:
            return {
                loading: true,
                error: null,
                interventions: [],
            };
        case INTERVENTIONS_FETCH_SUCCESS:
            return {
                loading: false,
                error: null,
                interventions: action.payload,
            };
        case INTERVENTIONS_FETCH_FAILURE:
            return {
                loading: false,
                error: action.payload,
                interventions: null,
            };
        case INTERVENTIONS_RESET:
            return {
                loading: false,
                error: null,
                interventions: null,
            };

        case INTERVENTIONS_UPDATE_OR_ADD:
            const intervention = action.payload;
            let found = false;
            let interventions = state.interventions
                ? state.interventions.map((m) => {
                      if (m.id === intervention.id) {
                          found = true;
                          return intervention;
                      }

                      return m;
                  })
                : [];

            if (!found) {
                interventions = [intervention, ...interventions];
            }

            return {
                ...state,
                interventions,
            };

        case INTERVENTIONS_API_REQUEST_START:
            return {
                ...state,
                error: null,
                progressApiCall: 0,
                loading: true,
            };
        case INTERVENTIONS_POST_PATCH_UPDATE_PROGRESS:
            return {
                ...state,
                loading: action.payload !== 100 ? true : false,
                progressApiCall: action.payload,
            };
        case INTERVENTIONS_POST_PATCH_SUCCESS:
            return {
                ...state,
                error: null,
                interventions: state.interventions?.map((intervention, index) => {
                    const data =
                        index === action.payload.index ? action.payload.promiseData : intervention;
                    return { ...data, syncState: syncStates.succeded, error: null };
                }),
            };
        case INTERVENTIONS_API_OPERATION_FAILURE:
            return {
                ...state,
                loading:
                    action.payload.loading !== undefined ? action.payload.loading : state.loading,
                interventions: state.interventions?.map((intervention, index) => {
                    return index === action.payload.index
                        ? {
                              ...intervention,
                              error: action.payload.error,
                              syncState: syncStates.failed,
                          }
                        : intervention;
                }),
            };
        case INTERVENTIONS_POST_PATCH_RESET:
            return {
                ...state,
                loading: false,
                error: null,
                interventions: null,
            };

        case INTERVENTIONS_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                interventions: state.interventions?.filter((intervention, index) => {
                    return index !== action.payload.index;
                }),
            };

        default:
            return state;
    }
};
