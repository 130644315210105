import styled from "styled-components";

export const FooterLayout = styled.footer`
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-color: white;
`;