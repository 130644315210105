import { FC } from "react";
import { Col, Row } from "react-bootstrap";
import { ISignaturesAware, ITechnicianAware } from "../../interfaces/model";
import SignatureImg from "../SignatureImg";

const SignatureViewer: FC<{ data: ISignaturesAware & ITechnicianAware }> = ({ data }) => {
    return (
        <>
            <Row className='my-3'>
                <Col>
                    <h6>
                        <b>Signature du technicien :</b>
                    </h6>
                    <h6>
                        <b>
                            <i>{data.technician?.username}</i>
                        </b>
                    </h6>
                </Col>
                <Col>
                    <h6>
                        <b>Signature du client :</b>
                    </h6>
                    <h6>
                        <b>
                            {data.customerSignature?.discharge
                                ? <i>Client absent</i>
                                : <i>{data.customerSignature?.signatory_fullname}</i>}
                        </b>
                    </h6>
                </Col>
            </Row>
            <Row>
                <Col>
                    <SignatureImg content={data.technicianSignature?.content} />
                </Col>
                <Col>

                    {data.customerSignature?.discharge ? (
                        <i>Client absent ou personnel non-autorisée à signer</i>
                    ) : (
                        <SignatureImg content={data.customerSignature?.content} />
                    )}
                </Col>
            </Row>
        </>
    );
};

export default SignatureViewer;
