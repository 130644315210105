import { FC } from "react";

import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";

import ScrollToTopWrapper from "./components/ScrollToTop";
import AuthProvider from "./components/AuthProvider";
import NotFound from "./components/NotFound";

import Login from "./pages/Login";
import Scan from "./pages/Scan";
import Home from "./pages/Home";
import MaintenancesList from "./pages/Maintenances.List";
import MaintenanceViewer from "./pages/Maintenance.Viewer";
import MaintenanceSignatures from "./pages/Maintenance.Signatures";
import MaintenanceEdit from "./pages/Maintenance.Edit";
import RentalHome from "./pages/Rental.Home";
import RentalInterventionEdit from "./pages/RentalIntervention.Edit";
import RentalInterventionSignatures from "./pages/RentalIntervention.Signatures";
import RentalInterventionViewer from "./pages/RentalIntervention.Viewer";

import "./assets/styles/_scss/style.scss";

const App: FC = () => {
    return (
        <Router>
            <ScrollToTopWrapper>
                <Routes>
                    <Route path='/' element={<Login />} />
                    <Route element={<AuthProvider />}>
                        <Route path='/scan' element={<Scan />} />
                        <Route path='/home' element={<Home />} />
                        <Route path='/maintenance/list' element={<MaintenancesList />} />
                        <Route path='/maintenance/create' element={<MaintenanceEdit />} />
                        <Route path='/maintenance/:id/edit' element={<MaintenanceEdit />} />
                        <Route path='/maintenance/:id/show' element={<MaintenanceViewer />} />
                        <Route path='/maintenance/:id/signatures' element={<MaintenanceSignatures />} />
                        <Route path='/rental/list' element={<RentalHome />} />
                        <Route path='/rental/intervention/:id/edit' element={<RentalInterventionEdit />} />
                        <Route path='/rental/intervention/create/:type' element={<RentalInterventionEdit />} />
                        <Route path='/rental/intervention/:id/signatures' element={<RentalInterventionSignatures />} />
                        <Route path='/rental/intervention/:id/show' element={<RentalInterventionViewer />} />
                        <Route path="/404" element={<NotFound />} />
                        <Route path="*" element={<Navigate replace to="/404" />} />
                    </Route>
                </Routes>
            </ScrollToTopWrapper>
        </Router>
    );
};

export default App;
