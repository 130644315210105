import { IInterventionModel, IUserModel } from "../interfaces/model";
import { createNewVibrationControls } from "./material";
import { isSignatureSigned } from "./signature";

export type rentalSignatureKeyEnum = "technicianSignature" | "customerSignature";

export const rentalStates = {
    pending: "pending",
    prepared: "prepared",
    delivered: "delivered",
    removed: "removed",
    completed: "completed",
};

export const interventionTypes = {
    preparation: "preparation",
    delivery: "delivery",
    removal: "removal",
    replensish: "replenish",
    refurbish: "refurbish",
};

export const rentalStateLabel = (state: string): string => {
    switch (state) {
        case rentalStates.pending:
            return "En attente";
        case rentalStates.prepared:
            return "Préparé";
        case rentalStates.delivered:
            return "Livré";
        case rentalStates.removed:
            return "Récupérée";
        case rentalStates.completed:
            return "Terminée";
        default:
            return "Inconnu";
    }
};

export const interventionTypeLabel = (type: string): string => {
    switch (type) {
        case interventionTypes.preparation:
            return "Préparation";
        case interventionTypes.delivery:
            return "Livraison";
        case interventionTypes.removal:
            return "Retrait";
        case interventionTypes.replensish:
            return "Réapprovisionnement";
        case interventionTypes.refurbish:
            return "Remise en état dans les locaux";
        default:
            return "Inconnu";
    }
};

export const requiredSignatures = (type: string): rentalSignatureKeyEnum[] => {
    switch (type) {
        case interventionTypes.preparation:
            return ["technicianSignature"];
        case interventionTypes.delivery:
            return ["technicianSignature", "customerSignature"];
        case interventionTypes.removal:
            return ["technicianSignature", "customerSignature"];
        case interventionTypes.replensish:
            return ["technicianSignature", "customerSignature"];
        case interventionTypes.refurbish:
            return ["technicianSignature"];
        default:
            return [];
    }
};

export const nextInterventionTypes = (type: string): string[] => {
    switch (type) {
        case interventionTypes.preparation:
            return [interventionTypes.delivery];
        case interventionTypes.delivery:
            return [interventionTypes.removal, interventionTypes.replensish];
        case interventionTypes.replensish:
            return [interventionTypes.removal, interventionTypes.replensish];
        case interventionTypes.removal:
            return [interventionTypes.refurbish];
        default:
            return [];
    }
};

export const interventionPosition = (type: string): number => {
    switch (type) {
        case interventionTypes.preparation:
            return 0;
        case interventionTypes.delivery:
            return 1;
        case interventionTypes.replensish:
            return 2;
        case interventionTypes.removal:
            return 3;
        case interventionTypes.refurbish:
            return 4;
        default:
            return -1;
    }
};

export const isInterventionComplete = (intervention: IInterventionModel): boolean => {
    let allSignature = true;
    requiredSignatures(intervention.type).forEach((rs) => {
        const signature = intervention[rs];
        if (!isSignatureSigned(signature)) {
            allSignature = false;
            return;
        }
    });

    return allSignature;
};

export const lastIntervention = (
    interventions: IInterventionModel[]
): IInterventionModel | null => {
    let maxPosition = -1;
    let last = null;
    interventions.forEach((intervention) => {
        const position = interventionPosition(intervention.type);
        if (isInterventionComplete(intervention) && position > maxPosition) {
            last = intervention;
            maxPosition = position;
        }
    });

    return last;
};

export const availableInterventionsTypes = (interventions: IInterventionModel[]): string[] => {
    let prev: any = "empty";
    const last = lastIntervention(interventions);
    if (last) {
        prev = isInterventionComplete(last) ? last.type : null;
    }

    if (prev === "empty") {
        return [interventionTypes.preparation];
    }
    return prev ? nextInterventionTypes(prev) : [];
};

export const canEditIntervention = (
    intervention: IInterventionModel,
    user: IUserModel
): boolean => {
    return !isInterventionComplete(intervention) && intervention.technician.id === user.id;
};

export const canDeleteIntervention = (intervention: IInterventionModel, user: IUserModel) => {
    return canEditIntervention(intervention, user);
};

export const canBeMultiple = (type: string): boolean => {
    return type === interventionTypes.replensish;
};


export const createNewIntervention = (values: any): IInterventionModel => {
    return {
        date: new Date().toISOString(),
        nbHours: null,
        maintenanceNbHours: null,
        comment: null,
        checkpointsComment: null,
        accessoriesComment: null,
        accessories: [],
        checkpoints: [],
        vibrationControlsComment: "",
        vibrationControlsAfterAssembly: createNewVibrationControls(),
        vibrationControlsBeforeAssembly: createNewVibrationControls(),
        ...values,
    }
};