import { FC } from "react";
import { IMaintenancesModel } from "../../interfaces/model";
import { dateTransformer } from "../../utils";
import { Table } from "react-bootstrap";

const GlobalDataHeaderViewer: FC<{ maintenance: IMaintenancesModel }> = ({ maintenance }) => {
    return (
        <Table className='shadow-sm border-2' responsive striped bordered hover>
            <tbody>
                <tr>
                    <th>
                        <b>Date</b>
                    </th>
                    <td>{dateTransformer(maintenance.date)}</td>
                </tr>
                <tr>
                    <th>
                        <b>Client</b>
                    </th>
                    <td>{maintenance.customer?.company}</td>
                </tr>
                <tr>
                    <th>
                        <b>Matériel</b>
                    </th>
                    <td>
                        <b>
                            {maintenance.material?.name} -{" "}
                            <i>Ref : {maintenance.material?.reference}</i>
                        </b>
                    </td>
                </tr>
                <tr>
                    <th>
                        <b>Technicien</b>
                    </th>
                    <td>{maintenance.technician?.username}</td>
                </tr>
                <tr>
                    <th>
                        <b>Adresse</b>
                    </th>
                    <td>
                        {maintenance.address?.street} <br />
                        {maintenance.address?.postcode} {maintenance.address?.city}
                    </td>
                </tr>
                <tr>
                    <th>
                        <b>Nombres d'heures d'utilisation</b>
                    </th>
                    <td>{maintenance.nbHours}</td>
                </tr>
            </tbody>
        </Table>
    );
};

export default GlobalDataHeaderViewer;
