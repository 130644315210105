import { FC } from "react";
import { getValueAtPath } from "../../utils";
import { useFormikContext } from "formik";

export const fieldErrors = (errors: any, path: string) => {
    const error = getValueAtPath(errors, path);
    let fieldErrors = null;
    if (error) {
        fieldErrors = Array.isArray(error) ? error : [error];
    }

    return fieldErrors;
};

export const showError = (
    touched: any,
    path: string,
    validateOnBlur: any,
    validateOnChange: any,
    submitCount: number,
    show: any
) => {
    const fieldTouched = getValueAtPath(touched, path);

    let showError = fieldTouched || (!validateOnBlur && !validateOnChange) || submitCount > 0;
    if (show !== undefined) {
        showError = typeof show === "boolean" ? show : show();
    }

    return showError;
};

const FormError: FC<{
    path: string;
    show?: () => boolean | boolean;
}> = ({ path, show }) => {
    const { errors, touched, validateOnBlur, validateOnChange, submitCount } = useFormikContext();
    const errorsResult = fieldErrors(errors, path);
    const showErrorResult = showError(
        touched,
        path,
        validateOnBlur,
        validateOnChange,
        submitCount,
        show
    );

    return (
        <>
            {errorsResult &&
                showErrorResult &&
                errorsResult.map((error, i) => (
                    <div
                        className='invalid-feedback text-danger'
                        style={{ display: "block" }}
                        key={error}>
                        {error}
                    </div>
                ))}
        </>
    );
};

export default FormError;
