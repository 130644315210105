import {
    MAINTENANCES_FETCH_REQUEST,
    MAINTENANCES_FETCH_SUCCESS,
    MAINTENANCES_FETCH_FAILURE,
    MAINTENANCES_API_REQUEST_START,
    MAINTENANCES_POST_PATCH_SUCCESS,
    MAINTENANCES_API_OPERATION_FAILURE,
    MAINTENANCES_UPDATE_OR_ADD,
    MAINTENANCES_POST_PATCH_INCREMENT_PROGRESS,
    MAINTENANCES_DELETE_SUCCESS,
} from "../reducer.types";

import {
    apiDeleteMaintenance,
    apiGetMaintenancesByMaterialId,
    apiSaveMaintenance,
} from "../../config/config.api.maintenances";

import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { RootState } from "../../store";

import { IMaterialModel, IMaintenancesModel } from "../../interfaces/model";
import { IReduxAwareAction } from "../../interfaces/action";
import { isNewResource, isSyncable } from "../../utils";
import { saveImagesBeforeSync } from "../../utils/saveImagesBeforeSync";

// Get Api Maintenances
export const getMaintenancesByMaterialId = (
    userToken: string,
    materialId: IMaterialModel["id"]
): ThunkAction<Promise<void>, RootState, unknown, AnyAction> =>
    async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>): Promise<void> => {
        try {
            dispatch({
                type: MAINTENANCES_FETCH_REQUEST,
            });

            const maintenancesData = await apiGetMaintenancesByMaterialId(userToken, materialId);

            dispatch({
                type: MAINTENANCES_FETCH_SUCCESS,
                payload: maintenancesData,
            });
        } catch (error: any) {
            dispatch({
                type: MAINTENANCES_FETCH_FAILURE,
                payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
            });
        }
    };

// Create or Patch Api Maintenances
export const saveMaintenances = (
    userToken: string,
    maintenances: IMaintenancesModel[]
): ThunkAction<Promise<void>, RootState, unknown, AnyAction> => async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>): Promise<void> => {
    try {
        dispatch({
            type: MAINTENANCES_API_REQUEST_START,
        });

        const progressData = {
            progress: 0,
            total: 0
        };

        maintenances.forEach((maintenance: IMaintenancesModel) => {
            if (isSyncable(maintenance)) {
                progressData.total++;
            }

            if (maintenance?.gallery?.images && maintenance?.gallery?.images.length > 0) {

                maintenance.gallery?.images.forEach((image) => {
                    if (image.base64) {
                        progressData.total++;
                    }
                });
            }
        });

        maintenances.forEach(async (maintenance: IMaintenancesModel, index: number) => {
            if (!isSyncable(maintenance)) {
                return;
            }

            const imageUploadResult = await saveImagesBeforeSync(
                userToken,
                maintenance,
                dispatch,
                MAINTENANCES_POST_PATCH_INCREMENT_PROGRESS,
                progressData
            );

            if (!imageUploadResult) {
                dispatch({
                    type: MAINTENANCES_API_OPERATION_FAILURE,
                    payload: {
                        index,
                        error: {
                            cathError: "Erreur lors de l'envoi d'une ou plusieurs photo(s)",
                            responseError: "Erreur lors de l'envoi d'une ou plusieurs photo(s)",
                        },
                    },
                });
                progressData.progress++;

                dispatch({
                    type: MAINTENANCES_POST_PATCH_INCREMENT_PROGRESS,
                    payload: (100 * progressData.progress) / progressData.total,
                });

                return;
            }

            apiSaveMaintenance(userToken, maintenance)
                .then((response) => {
                    dispatch({
                        type: MAINTENANCES_POST_PATCH_SUCCESS,
                        payload: {
                            promiseData: response.data,
                            index,
                        },
                    });
                })
                .catch((error) => {
                    dispatch({
                        type: MAINTENANCES_API_OPERATION_FAILURE,
                        payload: {
                            index,
                            error: {
                                cathError: error,
                                responseError: error.response,
                            },
                        },
                    });
                })
                .then(() => {
                    progressData.progress++;
                    dispatch({
                        type: MAINTENANCES_POST_PATCH_INCREMENT_PROGRESS,
                        payload: (100 * progressData.progress) / progressData.total,
                    });
                });
        });
    } catch (error: any) {
        console.log(error);
        dispatch({
            type: MAINTENANCES_API_OPERATION_FAILURE,
            payload: {
                index: 0,
                error: {
                    responseError: "Erreur inconnue: " + (error.toString !== undefined ? error.toString() : ''),
                }
            }
        });
    }

};

export const updateOrCreateMaintenance = (maintenance: IMaintenancesModel): IReduxAwareAction => ({
    type: MAINTENANCES_UPDATE_OR_ADD,
    payload: maintenance,
});

export const deleteMaintenance = (
    maintenance: IMaintenancesModel,
    index: number,
    userToken: string
): ThunkAction<Promise<void>, RootState, unknown, AnyAction> =>
    async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>): Promise<void> => {
        if (isNewResource(maintenance)) {
            dispatch({
                type: MAINTENANCES_DELETE_SUCCESS,
                payload: {
                    index,
                },
            });
        } else {
            dispatch({
                type: MAINTENANCES_API_REQUEST_START,
            });

            try {
                await apiDeleteMaintenance(userToken, maintenance);
                dispatch({
                    type: MAINTENANCES_DELETE_SUCCESS,
                    payload: {
                        index,
                    },
                });
            } catch (error: any) {
                dispatch({
                    type: MAINTENANCES_API_OPERATION_FAILURE,
                    payload: {
                        index,
                        loading: false,
                        error: {
                            cathError: error,
                            responseError: error.response,
                        },
                    },
                });
            }
        }
    };
