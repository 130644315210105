import * as Yup from 'yup';

const valueValidationSchema = Yup.number()
    .when('cannotBeDone', {
        is: (cannotBeDone: boolean) => cannotBeDone,
        then: Yup.number().nullable().notRequired(),
        otherwise: Yup.number()
            .typeError("Ce champ doit contenir un nombre")
            .required('Champs requis')
    })

const vibrationControlsValidationSchema = Yup.object().shape({
    cannotBeDone: Yup.boolean(),
    reducerMinValue: valueValidationSchema,
    reducerMaxValue: valueValidationSchema,
    powerSupplyMinValue: valueValidationSchema,
    powerSupplyMaxValue: valueValidationSchema,
});

export default vibrationControlsValidationSchema;