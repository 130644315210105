import {
    MATERIAL_FETCH_REQUEST,
    MATERIAL_FETCH_SUCCESS,
    MATERIAL_FETCH_FAILURE,
    ACCESSORIES_FETCH_SUCCESS,
    CHECKPOINTS_FETCH_SUCCESS,
    COUNTRIES_FETCH_SUCCESS,
    MAINTENANCES_FETCH_SUCCESS,
    RENTAL_FETCH_SUCCESS,
    INTERVENTIONS_FETCH_SUCCESS,
    INTERVENTIONS_RESET,
    ACCESSORIES_RESET,
    CHECKPOINTS_RESET,
    COUNTRIES_RESET,
    RENTAL_RESET,
    MAINTENANCES_RESET,
} from "../reducer.types";
import { apiGetMaterialByMaterialReference } from "../../config/config.api.material";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { RootState } from "../../store";
import { ISearchReferenceForm } from "../../interfaces/form/ISearchReferenceForm";
import { apiGetAccessories } from "../../config/config.api.accessories";
import { apiGetCheckpoints } from "../../config/config.api.checkpoints";
import { apiGetCountries } from "../../config/config.api.country";
import { apiGetMaintenancesByMaterialId } from "../../config/config.api.maintenances";
import { apiGetLastRentalInterventionByMaterialId } from "../../config/config.api.rental";
import { apiGetRentalsInterventionsByRentalId } from "../../config/config.api.interventions";

// Get Material By Material Reference
export const getMaterialData = (
    userToken: string,
    reference: ISearchReferenceForm["searchReference"]
): ThunkAction<Promise<void>, RootState, unknown, AnyAction> =>
    async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>): Promise<void> => {

        //first reset all data
        const resetTypes = [
            ACCESSORIES_RESET,
            CHECKPOINTS_RESET,
            COUNTRIES_RESET,
            MAINTENANCES_RESET,
            RENTAL_RESET,
            INTERVENTIONS_RESET,
        ];
        resetTypes.forEach(type => dispatch({ type }));

        try {
            dispatch({
                type: MATERIAL_FETCH_REQUEST,
            });

            const materialData = await apiGetMaterialByMaterialReference(userToken, reference);
            if (materialData.length === 0) {
                dispatch({
                    type: MATERIAL_FETCH_FAILURE,
                    payload: "Identifiant de la machine invalide",
                });
                return;
            }

            const materialId = materialData[0].id;

            const accessoriesData = await apiGetAccessories(userToken);
            dispatch({
                type: ACCESSORIES_FETCH_SUCCESS,
                payload: accessoriesData,
            });

            const checkpointsData = await apiGetCheckpoints(userToken);
            dispatch({
                type: CHECKPOINTS_FETCH_SUCCESS,
                payload: checkpointsData,
            });

            const countriesData = await apiGetCountries(userToken);
            dispatch({
                type: COUNTRIES_FETCH_SUCCESS,
                payload: countriesData,
            });


            const maintenancesData = await apiGetMaintenancesByMaterialId(userToken, materialId);
            dispatch({
                type: MAINTENANCES_FETCH_SUCCESS,
                payload: maintenancesData,
            });


            const lastRentalData = await apiGetLastRentalInterventionByMaterialId(userToken, materialId);
            dispatch({
                type: RENTAL_FETCH_SUCCESS,
                payload: lastRentalData,
            });

            // Check lastRentalData for doesn't send an empty value to the API request
            if (lastRentalData.length > 0) {
                const interventionsData = await apiGetRentalsInterventionsByRentalId(
                    userToken,
                    lastRentalData[0].id
                );

                dispatch({
                    type: INTERVENTIONS_FETCH_SUCCESS,
                    payload: interventionsData,
                });
            }

            dispatch({
                type: MATERIAL_FETCH_SUCCESS,
                payload: materialData,
            });
        } catch (error: any) {
            dispatch({
                type: MATERIAL_FETCH_FAILURE,
                payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
            });
        }
    };
