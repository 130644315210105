import { useFormikContext } from "formik";
import { FC, useMemo, useState } from "react";
import { IImagesModel, IMaintenancesModel, IMaterialModel } from "../../../interfaces/model";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { IMaterialState } from "../../../interfaces/state";
import { Button, Card, CloseButton, Modal } from "react-bootstrap";
import { CameraVideoOffFill, CheckSquareFill, PenFill, Square } from "react-bootstrap-icons";
import EditChildMaterial from "./EditChildMaterial";
import ModalConfirmation from "../../Modal/Modal.confirmation";
import { createNewVibrationControls, getResourceId, getVisibleChildrenForMaintenanceNbHours, materialChildrenIds } from "../../../utils";

const MaintenanceMaterialList: FC = () => {
    const { values, setFieldValue } = useFormikContext();
    const maintenance = values as IMaintenancesModel;
    const { maintenanceNbHours } = maintenance;
    const { material } = useSelector<RootState, IMaterialState>((state) => state.material)
    const [selectedChild, setSelectedChild] = useState<IMaterialModel | null>(null);
    const [showResetItemsConfirmation, setShowResetItemsConfirmation] = useState(false);
    const [showCloseSelectedChildModalConfirmation, setShowCloseSelectedChildModalConfirmation] = useState(false);

    const materialChildren = useMemo(
        () => getVisibleChildrenForMaintenanceNbHours(material as IMaterialModel, maintenanceNbHours), 
        [maintenanceNbHours, material]
    );

    const childHasImgError = (childMaterial: IMaterialModel): boolean => {
        let hasError = false;
        const ids = materialChildrenIds(childMaterial, true);

        maintenance.gallery?.images.forEach((image: IImagesModel) => {
            const materialId = image.options?.materialId;
            if (materialId && ids.includes(materialId) && image.error) {
                hasError = true;
            }
        });

        return hasError;
    }

    const childHasData = (childMaterial: IMaterialModel): boolean => {
        const ids = materialChildrenIds(childMaterial, true);
        const item = maintenance.materialItems?.find((item) => (ids.includes(getResourceId(item.material))));
        return item !== null && item !== undefined;
    }


    return (
        <>
            <label><b>Pièce(s) à controler</b></label>
            {materialChildren?.length === 0 && (
                <p>Aucune pièce à controler pour ce type de maintenance</p>
            )}
            {materialChildren?.map((childMaterial: IMaterialModel) => (
                <div key={childMaterial.id} className='mb-3'>
                    <Card className='shadow-sm'>
                        <Card.Body
                            className='d-flex align-items-center'
                            onClick={() => setSelectedChild(childMaterial)}
                        >
                            <Card.Text className='flex-grow-1 m-0'>
                                {childHasData(childMaterial) ?
                                    <CheckSquareFill color='#006400' className='me-2 hover-icon' /> :
                                    <Square color='#006400' className='me-2 hover-icon' />

                                }
                                {childMaterial.name}
                                {childHasImgError(childMaterial) && (
                                    <CameraVideoOffFill
                                        color='#ea4335'
                                        className='mx-2'
                                        size={20}
                                    />
                                )}
                            </Card.Text>
                            <PenFill color='#006400' className='me-2 hover-icon' />
                        </Card.Body>
                    </Card>
                </div>
            ))}
            {materialChildren?.length ? (
                <div className="text-center">
                    <Button
                        variant="danger"
                        color="white"
                        onClick={() => setShowResetItemsConfirmation(true)}
                    >
                        Effacer les données des pièces
                    </Button>
                    <ModalConfirmation
                        show={showResetItemsConfirmation}
                        textContent="Attention toutes vos saisies seront perdues"
                        closeCallBack={() => setShowResetItemsConfirmation(false)}
                        confirmCallBack={() => {
                            setFieldValue("materialItems", []);
                            setFieldValue("vibrationControlsBeforeAssembly", createNewVibrationControls());
                            setFieldValue("vibrationControlsAfterAssembly", createNewVibrationControls());
                            setFieldValue("gallery.images", []);
                            setFieldValue("vibrationControlsComment", "");
                            setShowResetItemsConfirmation(false);
                        }}
                    />
                </div>
            ) : null}

            {selectedChild && (
                <Modal show={true} onHide={() => setSelectedChild(null)} fullscreen={true}>
                    <Modal.Header className='bg-primary text-white'>
                        <Modal.Title>
                            Maintenance de {selectedChild.name}
                        </Modal.Title>
                        <CloseButton
                            variant='white'
                            onClick={() => setShowCloseSelectedChildModalConfirmation(true)}
                        />
                    </Modal.Header>
                    <Modal.Body>
                        <EditChildMaterial
                            material={selectedChild}
                            handleSubmit={values => {
                                //loop over values key and value
                                Object.entries(values).forEach(([key, value]) => {
                                    setFieldValue(key, value, false);
                                });

                                setSelectedChild(null)

                            }}
                        />
                    </Modal.Body>
                </Modal>
            )}

            <ModalConfirmation
                show={showCloseSelectedChildModalConfirmation}
                textContent="Attention toutes vos modifications seront perdues, voulez vous vraiment quitter ?"
                closeCallBack={() => setShowCloseSelectedChildModalConfirmation(false)}
                confirmCallBack={() => {
                    setSelectedChild(null);
                    setShowCloseSelectedChildModalConfirmation(false);
                }}
            />
        </>
    );
}

export default MaintenanceMaterialList;