import { Field, useFormikContext } from "formik";
import { FC } from "react";
import { getValueAtPath } from "../../utils";
import FormRow from "./FormRow";
import { Badge, Container } from "react-bootstrap";

const FieldVibrationControls: FC = () => {
    const { values, setFieldValue, setValues } = useFormikContext();
    const fields = [
        { field: "vibrationControlsBeforeAssembly", label: "Avant intervention" },
        { field: "vibrationControlsAfterAssembly", label: "Après intervention" },
    ];

    return (
        <>
            {fields.map(({ field, label }) => (
                <Container key={field}>
                    <h5>
                        <Badge bg='primary'>{label}</Badge>
                    </h5>
                    <div className='py-2'>
                        <div className='form-check'>
                            <Field
                                type='checkbox'
                                name={`${field}.cannotBeDone`}
                                id={`${field}_cannotBeDone`}
                                className='form-check-input my-2'
                                onChange={(e: any) => {
                                    setFieldValue(`${field}.cannotBeDone`, e.target.checked);

                                    if (e.target.checked) {
                                        setFieldValue(`${field}.reducerMinValue`, null, false);
                                        setFieldValue(`${field}.reducerMaxValue`, null, false);
                                        setFieldValue(`${field}.powerSupplyMinValue`, null, false);
                                        setFieldValue(`${field}.powerSupplyMaxValue`, null, false);
                                    }
                                }}
                            />
                            <label
                                className='form-check-label ps-1 my-1'
                                htmlFor={`${field}_cannotBeDone`}>
                                <b>Les contrôles n'ont pas pu être faits</b>
                            </label>
                        </div>
                        {!getValueAtPath(values, `${field}.cannotBeDone`) && (
                            <>
                                <h6 className="text-decoration-underline mt-4">
                                    <strong>Côté réducteur</strong>
                                </h6>
                                <div className="d-flex flex-column flex-sm-row justify-content-around">
                                    <FormRow
                                        name={`${field}.reducerMinValue`}
                                        label='Vitesse minimale (mm/s)'>
                                        <Field className='form-control' type='number' />
                                    </FormRow>
                                    <FormRow
                                        name={`${field}.reducerMaxValue`}
                                        label='Vitesse maximale (mm/s)'>
                                        <Field className='form-control' type='number' />
                                    </FormRow>
                                </div>
                                <h6 className="text-decoration-underline mt-4">
                                    <strong>Côté palier d'alimentation</strong>
                                </h6>
                                <div className="d-flex flex-column flex-sm-row justify-content-around">
                                    <FormRow
                                        name={`${field}.powerSupplyMinValue`}
                                        label="Vitesse minimale (mm/s)">
                                        <Field className='form-control' type='number' />
                                    </FormRow>
                                    <FormRow
                                        name={`${field}.powerSupplyMaxValue`}
                                        label="Vitesse maximale (mm/s)">
                                        <Field className='form-control' type='number' />
                                    </FormRow>
                                </div>
                            </>
                        )}
                    </div>
                </Container>
            ))}
            <FormRow name='vibrationControlsComment' label='Commentaire'>
                <Field as='textarea' className='form-control textarea-resize' rows='5' />
            </FormRow>
        </>
    );
};

export default FieldVibrationControls;
