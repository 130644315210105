import styled from "styled-components";

export const TitleLayout = styled.div`
    .title {
        margin: 80px 0 50px;
        text-align: center;
    }

    .container-title-layout {
        padding-bottom: 200px;
    }
`;
