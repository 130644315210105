import styled from "styled-components";

interface OverlayProps {
    readonly isOpenedOverlay: boolean;
}

export const OverlayLoadingStyled = styled.div<OverlayProps>`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 250ms ease-in-out;
    pointer-events: none;
    ${(props) => props.isOpenedOverlay &&
        `opacity: 1;
        pointer-events: all;`
    }
`;
