import * as Yup from "yup";
import { IMaintenanceMaterialItemModel } from "../interfaces/model";

export const validationMaintenanceSchema = Yup.object().shape({
    nbHours: Yup.number()
        .typeError("Ce champ doit contenir un nombre")
        .integer("Ce champ doit être un entier")
        .notOneOf([0], "Ce champ doit être différent de 0")
        .required("Champ requis"),
    address: Yup.object().shape({
        street: Yup.string().required("Champ requis"),
        city: Yup.string().required("Champ requis"),
        postcode: Yup.string().required("Champ requis"),
        countryCode: Yup.string().required("Champ requis"),
    }),
    materialChildComment: Yup.string().notRequired(),
    generalComment: Yup.string().notRequired(),
    greaseReplacementComment: Yup.string().when(
        ["materialItems"],
        {
            is: (
                materialItems: IMaintenanceMaterialItemModel[],
            ) => {
                if (materialItems === null || materialItems === undefined) {
                    return false;
                }

                if (materialItems.length > 0) {
                    return materialItems.some((item) => item.isGreaseReplacement);
                }
            },
            then: Yup.string().required("Veuillez renseigner les références des graisses changées"),
            otherwise: Yup.string().notRequired(),
        }
    ),
});
