import { IMaintenancesModel, IUserModel } from "../interfaces/model";
import { createNewVibrationControls } from "./material";
import { isSignatureSigned } from "./signature";

export type maintenanceSignatureKeyEnum = "technicianSignature" | "customerSignature";

export const isMaintenanceComplete = (maintenance: IMaintenancesModel): boolean => {
    let allSignature = true;
    const keys = ["technicianSignature", "customerSignature"] as maintenanceSignatureKeyEnum[];
    keys.forEach((rs: maintenanceSignatureKeyEnum) => {
        const signature = maintenance[rs];
        if (!isSignatureSigned(signature)) {
            allSignature = false;
            return;
        }
    });

    return allSignature;
};

export const canEditMaintenance = (maintenance: IMaintenancesModel, user: IUserModel): boolean => {
    return !isMaintenanceComplete(maintenance) && maintenance.technician.id === user.id;
};

export const canDeleteMaintenance = (maintenance: IMaintenancesModel, user: IUserModel) => {
    return canEditMaintenance(maintenance, user);
};

export const nbHoursChoices = [3000, 6000, 9000, 12000, 15000, 18000, 22000, 24000];

export const maintenanceInterventionTypes = {
    maintenance: "maintenance",
    repair: "repair",
    both: "both",
};

export const maintenanceInterventionTypeLabel = (type: string): string => {
    switch (type) {
        case maintenanceInterventionTypes.maintenance:
            return "Maintenance";
        case maintenanceInterventionTypes.repair:
            return "Réparation";
        case maintenanceInterventionTypes.both:
            return "Les deux";
        default:
            return "Inconnu";
    }
};

export const createNewMaintenance = (values: any = null): IMaintenancesModel => {
    return {

        nbHours: 0,
        maintenanceNbHours: 0,
        address: {
            street: "",
            city: "",
            postcode: "",
            countryCode: "",
        },
        interventionType: "",
        materialItemsComment: "",
        greaseReplacementComment: "",
        comment: "",
        extraSpareParts: [],
        date: new Date().toISOString(),
        technician: null,
        customer: null,
        vibrationControlsComment: "",
        vibrationControlsAfterAssembly: createNewVibrationControls(),
        vibrationControlsBeforeAssembly: createNewVibrationControls(),
        ...values
    };
}