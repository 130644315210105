import { FC } from "react";
import { CheckCircleFill, ClockFill, ExclamationTriangleFill } from "react-bootstrap-icons";
import { syncStateLabel, syncStates, syncStateColor } from "../utils";

const SyncState: FC<{ state: string }> = ({ state }) => {
    let icon = <CheckCircleFill />;
    switch (state) {
        case syncStates.awaiting:
            icon = <ClockFill />;
            break;

        case syncStates.failed:
            icon = <ExclamationTriangleFill />;
    }

    return (
        <div className={"text-" + syncStateColor(state)}>
            <span className='me-2'>{icon}</span>
            {syncStateLabel(state)}
        </div>
    );
};

export default SyncState;
