import { FC } from 'react'
import { Col, Row } from 'react-bootstrap'
import { IMaintenancesModel } from '../../interfaces/model'

const ViewerGreaseReplacementComment: FC<{ data: IMaintenancesModel }> = ({ data }) => {
  return (
    <>
        {data.greaseReplacementComment && (
            <>
                <hr className='w-100 my-5' />
                <Row className='mt-3'>
                    <Col>
                        <h6>
                            <u>
                                <b>
                                    Références des graisses remplacées :
                                </b>
                            </u>
                        </h6>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p>{data.greaseReplacementComment}</p>
                    </Col>
                </Row>
            </>
        )}
    </>
  )
}

export default ViewerGreaseReplacementComment