import { FC, useCallback, useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";

import { RootState } from "../store";
import { useDispatch, useSelector } from "react-redux";
import { updateOrCreateIntervention } from "../redux/actions";
import { selectInterventionById } from "../redux/selectors";

import SignaturePad from "../components/Signature.Pad";
import { TitleLayout } from "../components/Styled/Title.Layout";
import NavbarMenu from "../components/Navbar/Navbar.menu";

import { ISignaturePadModel } from "../interfaces/model";

import { requiredSignatures, rentalSignatureKeyEnum, syncStates, getValueAtPath } from "../utils";

import { Formik } from "formik";
import { customerSignatureSchema, technicianSignatureSchema } from "../validation/signature.schema";

import { Container } from "react-bootstrap";
import { ExclamationTriangle } from "react-bootstrap-icons";

const RentalInterventionSignatures: FC = () => {
    const id = useParams().id;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [currentPath, setCurrentPath] = useState<rentalSignatureKeyEnum>("technicianSignature");
    const state = useSelector<RootState, RootState>((state) => state);
    const intervention = selectInterventionById(state, id);
    const signature = {
        content: "",
        signatory_fullname: "",
        discharge: false,
        done: false,
    };

    const requiredSignaturesResult = intervention && requiredSignatures(intervention.type);
    const validationSchema = currentPath === "technicianSignature" ? technicianSignatureSchema : customerSignatureSchema;
    const currentSignature = getValueAtPath(intervention!, currentPath) as ISignaturePadModel;

    const nextStep = useCallback(() => {
        if (requiredSignaturesResult) {
            if (currentPath === "technicianSignature" && requiredSignaturesResult.includes("customerSignature")) {
                setCurrentPath("customerSignature");
            } else {
                navigate("/rental/list");
            }
        }
    }
    , [currentPath, navigate, requiredSignaturesResult]);

    const handleSubmit = (signature: ISignaturePadModel, data: any) => {
        const newIntervention: any = { 
            ...intervention,
            syncState: syncStates.awaiting,
        };
        newIntervention[currentPath] = {
            ...signature,
            done: true,
        };
        dispatch(updateOrCreateIntervention(newIntervention));
        data.resetForm({ values: { ...signature } });
        nextStep();
    };

    useEffect(() => {
        if (currentSignature && currentSignature.done) {
            nextStep();
        }
    }, [currentSignature, nextStep]);

    return (
        <TitleLayout>
            <NavbarMenu navbarTitle='Saisie intervention' />
            <h2 className='title'>
                Signature {currentPath === "technicianSignature" ? "du technicien" : "du client"}
            </h2>
            <Container className='container-title-layout' fluid>
                <p className='d-flex flex-column align-items-center align-items-md-start flex-md-row justify-content-center text-center text-danger mb-5'>
                    <ExclamationTriangle
                        style={{ minWidth: "32px" }}
                        className='mb-3 mb-md-0 mt-md-1 me-md-2'
                    />
                    Une fois la signature validée vous ne pourrez plus la modifier, une fois que le
                    client a signé l'intervention vous ne pourrez plus la modifier
                </p>
                <Formik
                    enableReinitialize={true}
                    initialValues={signature}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}>
                    {({ submitForm }) => (
                        <SignaturePad
                            path={currentPath}
                            signatoryInfo={currentPath === "customerSignature"}
                            handleSubmit={submitForm}
                        />
                    )}
                </Formik>
            </Container>
        </TitleLayout>
    );
};

export default RentalInterventionSignatures;
