import * as Yup from "yup";


const ignoreWhenNotDischarge = Yup.string()
  .when('discharge', {
    is: (discharge: boolean) => discharge === false,
    then: Yup.string().required("Champ requis"),
    otherwise: Yup.string().notRequired()
  });

export const customerSignatureSchema = Yup.object().shape({
  content: ignoreWhenNotDischarge,
  discharge: Yup.boolean(),
  signatory_fullname: ignoreWhenNotDischarge,
});

export const technicianSignatureSchema = Yup.object().shape({
  content: Yup.string().required("Signature requise"),
});

export const validationSignatureSchema = Yup.object().shape({
  customerSignature: customerSignatureSchema,
  technicianSignature: technicianSignatureSchema,
});