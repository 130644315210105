import { FC } from "react";
import { Badge } from "react-bootstrap";

const BooleanBadge: FC<{ value: boolean }> = ({ value }) => {
    return (
        <Badge bg={value ? 'success' : 'danger'}>
            {value ? 'Oui' : 'Non'}
        </Badge>
    )
}

export default BooleanBadge;