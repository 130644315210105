import { apiBaseUrl, defaultSaveConfig } from "./config.api.baseUrl";
import { baseMaterialIri } from "./config.api.material";
import { baseMediaObjectIri } from "./config.api.mediaObject";

import { IMaterialModel, IMaintenancesModel } from "../interfaces/model";

export const baseMaintenancesIri = "/api/maintenances";
export const baseCustomerIri = "/api/customers";
export const maintenanceConfigApiFormat = [
    { path: "material", config: { baseIri: baseMaterialIri }, type: "transformIri" },
    { path: "customer", config: { baseIri: baseCustomerIri }, type: "transformIri" },
    { path: "materialItems.material", config: { baseIri: baseMaterialIri }, type: "transformIri" },
    { path: "gallery.images", config: { baseIri: baseMediaObjectIri }, type: "addIri", method: "PATCH" },
    { path: "gallery.images", config: { baseIri: baseMediaObjectIri }, type: "transformIri", method: "POST" },
    { path: "materialItems.images", type: "unset"},
    { path: "syncState", type: "unset"},
    { path: "error", type: "unset"},
];

// Create Get Request for Maintenances by Material ID
export const apiGetMaintenancesByMaterialId = async (
    userToken: string,
    materialId: IMaterialModel["id"]
) => {
    const response = await apiBaseUrl(userToken).get(
        `${baseMaintenancesIri}?material.id=${materialId}`
    );
    return response.data;
};

// Create Get Request for Maitenance by Maintenance ID
export const apiGetMaintenanceById = async (
    userToken: string,
    maintenanceId: IMaintenancesModel["id"]
) => {
    const response = await apiBaseUrl(userToken).get(`${baseMaintenancesIri}/${maintenanceId}`);
    return response.data;
};

// Save Maintenances
export const apiSaveMaintenance = (userToken: string, maintenanceNotSynced: IMaintenancesModel) => {
    const response = apiBaseUrl(userToken)(
        defaultSaveConfig(maintenanceNotSynced, baseMaintenancesIri, maintenanceConfigApiFormat)
    );
    return response;
};

export const apiDeleteMaintenance = (userToken: string, maintenance: IMaintenancesModel) => {
    return apiBaseUrl(userToken).delete(`${baseMaintenancesIri}/${maintenance.id}`, {
        headers: {
            Authorization: `Bearer ${userToken}`,
        },
    });
};
