import { Field, FieldArray, Formik, useFormikContext } from "formik";
import { ChangeEvent, FC, useState } from "react";
import { Button, Card, Modal } from "react-bootstrap";
import { PencilSquare, PlusCircle, Trash } from "react-bootstrap-icons";
import { useSelector } from "react-redux";
import { selectAccessoriesList } from "../../../redux/selectors";
import {
    IAccessoryModel,
    IInterventionAccessoryModel,
    IInterventionModel,
} from "../../../interfaces/model";
import { getResourceId, getValueAtPath, materialStateLabel } from "../../../utils";
import { RootState } from "../../../store";
import FieldMaterialState from "../FieldMaterialState";
import FormRow from "../FormRow";
import { interventionAccessoryValidationScema } from "../../../validation/intervention.schema";

const FieldAccessoryList: FC = () => {
    const state = useSelector<RootState, RootState>((state) => state);
    const accessories = selectAccessoriesList(state);
    const { values, errors, isValidating, setFieldValue } = useFormikContext();
    const intervention = values ? (values as IInterventionModel) : ({} as IInterventionModel);
    const [currentIndex, setCurrentIndex] = useState<number | null>(null);

    return (
        <>
            <FieldArray
                name='accessories'
                render={(arrayHelpers) => (
                    <div>
                        {intervention.accessories &&
                            intervention.accessories.map(
                                (accessory: IInterventionAccessoryModel, index) => (
                                    <div key={index} className='mb-3'>
                                        <Card className='shadow'>
                                            <Card.Body>
                                                <div className='d-flex'>
                                                    <div className='flex-grow-1'>
                                                        {
                                                            accessories.find(
                                                                (a) =>
                                                                    a.id ===
                                                                    getResourceId(
                                                                        accessory.accessory
                                                                    )
                                                            )?.name
                                                        }
                                                        <br />
                                                        Qte: {accessory.quantity} - État:{" "}
                                                        {materialStateLabel(accessory.state)}
                                                        {getValueAtPath(
                                                            errors,
                                                            `accessories.${index}`
                                                        ) !== null && (
                                                                <div className='text-danger'>
                                                                    Cet accessoire contient des erreurs
                                                                </div>
                                                            )}
                                                    </div>
                                                    <div className='me-0'>
                                                        <div className='btn-group'>
                                                            <Button
                                                                variant='outline-warning'
                                                                type='button'
                                                                size='sm'
                                                                onClick={() =>
                                                                    setCurrentIndex(index)
                                                                }>
                                                                <PencilSquare />
                                                            </Button>
                                                            <Button
                                                                variant='outline-danger'
                                                                type='button'
                                                                size='sm'
                                                                onClick={() =>
                                                                    arrayHelpers.remove(index)
                                                                }>
                                                                <Trash />
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                )
                            )}
                        <Button
                            variant='outline-dark'
                            type='button'
                            size='sm'
                            onClick={() => {
                                setCurrentIndex(
                                    intervention.accessories ? intervention.accessories.length : 0
                                );
                            }}>
                            <PlusCircle className='me-2' />
                            Ajouter un accessoire
                        </Button>
                        {currentIndex !== null && (
                            <Modal
                                show={currentIndex !== null}
                                centred='true'
                                backdrop='static'
                                onHide={() => {
                                    setCurrentIndex(null)
                                }}
                                aria-labelledby='contained-modal-title-vcenter'
                            >
                                <Formik
                                    validationSchema={interventionAccessoryValidationScema}
                                    initialValues={
                                        getValueAtPath(values, `accessories[${currentIndex}]`) as IInterventionAccessoryModel ??
                                        { quantity: 1, state: "good" } as IInterventionAccessoryModel
                                    }
                                    onSubmit={(acessorieValues) => {
                                        setFieldValue(`accessories[${currentIndex}]`, acessorieValues, false);
                                        setCurrentIndex(null);
                                    }}
                                >
                                    {subFormProps => (
                                        <form onSubmit={subFormProps.handleSubmit}>
                                            <Modal.Header closeButton>
                                                <Modal.Title id='contained-modal-title-vcenter'>
                                                    Accessoire
                                                </Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <FormRow
                                                    name={`accessory`}
                                                    label='Accessoire'
                                                    errorProps={{ show: () => true }}>
                                                    <Field
                                                        id={`accessory`}
                                                        name={`accessory`}
                                                        className='form-control'
                                                        as='select'
                                                        required
                                                        value={String(
                                                            getResourceId(subFormProps.values?.accessory)
                                                        )}
                                                        onChange={(
                                                            e: ChangeEvent<HTMLInputElement>
                                                        ) => {
                                                            const accessory = accessories.find(
                                                                (a) => a.id == e.target.value
                                                            );
                                                            subFormProps.setFieldValue(`accessory`, accessory);
                                                        }}>
                                                        <option value=''>-</option>
                                                        {accessories.map((a: IAccessoryModel) => (
                                                            <option key={a.id} value={a.id}>
                                                                {a.name}
                                                            </option>
                                                        ))}
                                                    </Field>
                                                </FormRow>
                                                <FormRow
                                                    name={`.quantity`}
                                                    label='Quantité'>
                                                    <Field
                                                        type='number'
                                                        name={`quantity`}
                                                        id={`quantity`}
                                                        className='form-control'
                                                    />
                                                </FormRow>
                                                <FormRow
                                                    name={`state`}
                                                    label='État'>
                                                    <FieldMaterialState
                                                        name={`state`}
                                                    />
                                                </FormRow>
                                                <Button
                                                    disabled={subFormProps.isSubmitting}
                                                    variant='success'
                                                    className='w-100'
                                                    onClick={() => subFormProps.handleSubmit()}>
                                                    Valider
                                                </Button>
                                            </Modal.Body>
                                        </form>
                                    )}
                                </Formik>
                            </Modal>
                        )}
                    </div>
                )}
            />
        </>
    );
};

export default FieldAccessoryList;
