// Utility function to get value from a nested object or array
export function getValueAtPath(object: any, path: string): unknown {

	if (! object) {
		return null;
	}

	const segments = path.split(/[.[\]]/).filter(Boolean);
	let value: any = object;

	for (const segment of segments) {
		if (value === undefined || value === null) {
			return null;
		}

		if (segment.endsWith(']')) {
			const index = parseInt(segment.slice(0, -1));
			value = value[index];
		} else {
			value = value[segment];
		}
	}

	return value;
}