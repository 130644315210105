import { Field, useFormikContext } from "formik";
import { ChangeEvent, FC } from "react";
import { nbHoursChoices } from "../../utils";

const FieldNbHours: FC<{name?:string}> = ({name, ...props}) => {
    const { setFieldValue } = useFormikContext();
    return (
        <Field
            as='select'
            type='number'
            className='form-control shadow-sm'
            name={name}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setFieldValue(
                    name as string,
                    +e.target.value
                );
            }}
            {...props}
        >
            <option value=''>
                Sélectionnez un nombre d'heures
            </option>
            {nbHoursChoices.map((nbHour) => (
                <option value={nbHour} key={nbHour}>
                    {nbHour}
                </option>
            ))}
        </Field>
    );
}

export default FieldNbHours;