import { FC } from "react";
import { Container, Navbar } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { IUserState } from "../../interfaces/state";

const NavbarTitle: FC<{ title: string; }> = ({
  title,
}) => {
    const { userData } = useSelector<RootState, IUserState>((state) => state.userLogin);
    const userConnected = userData?.user?.username;
  
  return (
    <Container fluid>
      <Navbar fixed="top" bg="primary" expand="lg" className="shadow">
        {!userConnected ? (
          <Navbar.Brand className="text-white mx-auto">{title}</Navbar.Brand>
        ) : (
          <Container>
            <Navbar.Brand className="text-white">{title}</Navbar.Brand>
            <p className="text-white m-0">
              {userConnected}
            </p>
          </Container>
        )}
      </Navbar>
    </Container>
  );
};

export default NavbarTitle;
