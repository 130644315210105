import { FC } from "react";


const SignatureImg: FC<{ content: string | undefined}> = ({ content }) => {
    return (
        <>
            {content ?
                <img
                    src={content}
                    alt="Signature"
                    className='img-fluid alt-style'
                /> :
                <p>Non signée pour l'instant</p>
            }
        </>);
}

export default SignatureImg;