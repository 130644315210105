import { ICountriesState } from "../../interfaces/state";
import { IReduxAwareAction } from "../../interfaces/action";
import { COUNTRIES_FETCH_SUCCESS, COUNTRIES_RESET } from "../reducer.types";

export const countriesReducer = (
    state: ICountriesState = { countries: null },
    action: IReduxAwareAction
) => {
    switch (action.type) {
        case COUNTRIES_FETCH_SUCCESS:
            return {
                loading: false,
                error: null,
                countries: action.payload,
            };

        case COUNTRIES_RESET:
            return {
                loading: false,
                error: null,
                countries: null,
            };

        default:
            return state;
    }
};