import { FC, useCallback, useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { selectMaintenanceById } from '../redux/selectors';
import { RootState } from '../store';
import { updateOrCreateMaintenance } from '../redux/actions';

import { ISignaturePadModel, IMaintenancesModel } from '../interfaces/model';
import { customerSignatureSchema, technicianSignatureSchema } from '../validation/signature.schema';

import NavbarMenu from "../components/Navbar/Navbar.menu";
import SignaturePad from '../components/Signature.Pad';
import { TitleLayout } from '../components/Styled/Title.Layout';
import { getValueAtPath, syncStates } from '../utils';

import { Formik } from 'formik';
import { Container } from 'react-bootstrap';
import { ExclamationTriangle } from 'react-bootstrap-icons';

type pathEnum = 'technicianSignature' | 'customerSignature';

const MaintenanceSignatures: FC = () => {
    const id = useParams().id;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [currentPath, setCurrentPath] = useState<pathEnum>('technicianSignature');
    const state = useSelector<RootState, RootState>((state) => state);
    const maintenance = selectMaintenanceById(state, id) as IMaintenancesModel;
    const signature = {
        content: '',
        signatory_fullname: '',
        discharge: false,
        done: false,
    };
    const validationSchema = currentPath === 'technicianSignature' ? technicianSignatureSchema : customerSignatureSchema;
    const currentSignature = getValueAtPath(maintenance, currentPath) as ISignaturePadModel;

    const nextStep = useCallback(() => {
        if (currentPath === 'technicianSignature') {
            setCurrentPath('customerSignature');
        } else {
            navigate('/maintenance/list');
        }
    }, [currentPath, navigate]);

    const handleSubmit = (signature: ISignaturePadModel, data: any) => {
        const newMaintenance = {
            ...maintenance,
            syncState: syncStates.awaiting,
        };

        newMaintenance[currentPath] = {
            ...signature,
            done: true
        };

        dispatch(updateOrCreateMaintenance(newMaintenance));
        data.resetForm({ values: { ...signature } });
        nextStep();
    }

    useEffect(() => {
        if (currentSignature && currentSignature.done) {
            nextStep();
        }
    }, [currentSignature, nextStep]);

    return (
        <TitleLayout>
            <NavbarMenu navbarTitle='Saisie maintenance' />
            <h2 className='title'>Signature {currentPath === 'technicianSignature' ? 'du technicien' : 'du client'}</h2>
            <Container className='container-title-layout' fluid>
                <p className='d-flex flex-column align-items-center align-items-md-start flex-md-row justify-content-center text-center text-danger mb-5'>
                    <ExclamationTriangle style={{ minWidth: '32px' }} className='mb-3 mb-md-0 mt-md-1 me-md-2' />
                    Une fois la signature validée vous ne pourrez plus la modifier, une fois que le client a signé la maintenance vous ne pourrez plus la modifier
                </p>
                <Formik
                    enableReinitialize={true}
                    initialValues={signature}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}>
                    {({ submitForm }) => (
                        <SignaturePad
                            path={currentPath}
                            signatoryInfo={currentPath === 'customerSignature'}
                            handleSubmit={submitForm}
                        />
                    )}
                </Formik>
            </Container>
        </TitleLayout>
    );
}

export default MaintenanceSignatures;

