import {
    MATERIAL_FETCH_REQUEST,
    MATERIAL_FETCH_SUCCESS,
    MATERIAL_FETCH_FAILURE,
    MATERIAL_RESET,
} from "../reducer.types";

import { IMaterialState } from "../../interfaces/state";
import { IReduxAwareAction } from "../../interfaces/action";

// Material Reducer
export const materialReducer = (
    state: IMaterialState = { material: null },
    action: IReduxAwareAction
) => {
    switch (action.type) {
        case MATERIAL_FETCH_REQUEST:
            return {
                loading: true,
                error: null,
                material: null,
            };
        case MATERIAL_FETCH_SUCCESS:
            return {
                loading: false,
                error: null,
                material: action.payload[0],
            };
        case MATERIAL_FETCH_FAILURE:
            return {
                loading: false,
                error: action.payload,
                material: null,
            };

        case MATERIAL_RESET:
            return {
                loading: false,
                error: null,
                material: null,
            };
        default:
            return state;
    }
};
