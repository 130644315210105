import { FC } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { Link } from "react-router-dom";
import { selectMaterialItem } from "../redux/selectors";
import NavbarTitle from "../components/Navbar/Navbar.title";
import Logo from "../assets/img/Logo.png";
import { BaseLayout } from "../components/Styled/Base.Layout";
import { LogoContainer } from "../components/Styled/Logo.Container";

const Home: FC = () => {
    // Handle Retrieve State
    const state = useSelector<RootState, RootState>((state) => state);
    const material = selectMaterialItem(state);


    return (
        <BaseLayout>
            <NavbarTitle title='Accueil' />
            <h2 className='mb-5'>{material?.name}</h2>
            <Link to='/rental/list' className='btn btn-primary my-4 w-75'>
                Location
            </Link>

            <Link to='/maintenance/list' className='btn btn-primary my-4 w-75'>
                Liste des maintenances
            </Link>

            <Link to='/maintenance/create' className='btn btn-primary my-4 w-75'>
                Créer une maintenance
            </Link>
            <Link
                to='/scan'
                className='btn btn-primary text-white my-4 w-75'>
                Retour à la recherche
            </Link>
            <LogoContainer src={Logo} alt='logo LCMI' />
        </BaseLayout>
    );
};

export default Home;
