import { FC, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { selectInterventionById, selectTypedCheckpointList } from "../redux/selectors";
import { RootState } from "../store";
import { updateOrCreateIntervention } from "../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import NavbarMenu from "../components/Navbar/Navbar.menu";
import FieldCheckpointList from "../components/Form/RentalIntervention/FieldCheckpointList";
import FieldAccessoryList from "../components/Form/RentalIntervention/FieldAccessoryList";
import FieldVibrationControls from "../components/Form/FieldVibrationControls";
import FormRow from "../components/Form/FormRow";
import { FooterLayout } from "../components/Styled/Footer.Layout";
import { TitleLayout } from "../components/Styled/Title.Layout";
import { nanoid } from "nanoid";
import { createNewIntervention, dateTransformer, getValueAtPath, syncStates } from "../utils";
import { IUserState, IRentalState } from "../interfaces/state";
import { IInterventionModel } from "../interfaces/model";
import { interventionValidationSchema } from "../validation/intervention.schema";
import { Formik, Form, Field, yupToFormErrors } from "formik";
import { Button, Col, Container, Row } from "react-bootstrap";
import FieldGallery from "../components/Form/FieldGallery";
import PromptIfDirty from "../components/PromptIfDirty";

const RentalInterventionEdit: FC = () => {
    const id = useParams().id;
    const type = useParams().type;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const state = useSelector<RootState, RootState>((state) => state);
    const userConnected = useSelector<RootState, IUserState>((state) => state.userLogin);
    const checkpoints = selectTypedCheckpointList(state, type!);
    const { rental } = useSelector<RootState, IRentalState>((state) => state.rental);

    let intervention: IInterventionModel | null = null;
    const [interventionNotFoundShowMessage, setInterventionNotFoundShowMessage] = useState<boolean>(false);

    if (id) {
        intervention = selectInterventionById(state, id);
        !intervention && setInterventionNotFoundShowMessage(true);
    }

    const interventionNotFoundMessage = (): JSX.Element => {
        return (
            <>
                <h2>Pas d'interventions trouvées pour cette location</h2>
                <Button
                    variant="warning"
                    onClick={() => {
                        setInterventionNotFoundShowMessage(false);
                        navigate("/rental/list");
                    }}>
                    Retour à la liste
                </Button>
            </>
        );
    };

    if (!intervention) {
        intervention = createNewIntervention({
            technician: {
                ...userConnected.userData?.user,
            },
            type,
        });
    }

    let signAfterSubmit: boolean = false;
    const handleSubmit = (values: IInterventionModel) => {
        const newIntervention = {
            ...values,
            id: values.id ?? nanoid(),
            syncState: syncStates.awaiting,
            rental: values.rental ?? { ...rental },
        } as IInterventionModel;
        dispatch(updateOrCreateIntervention(newIntervention));
        navigate(
            signAfterSubmit
                ? `/rental/intervention/${newIntervention.id}/signatures`
                : "/rental/list"
        );
    };

    return (
        <TitleLayout>
            <NavbarMenu navbarTitle='Location' />
            <h2 className='title'>Édition intervention de location</h2>
            {interventionNotFoundShowMessage ? (
                interventionNotFoundMessage()
            ) : (
                <Formik
                    enableReinitialize={true}
                    initialValues={intervention}
                    validate={async (values) => {
                        const context = { checkpoints };
                        try {
                            await interventionValidationSchema.validate(values, {
                                abortEarly: false,
                                context,
                            });
                            return {};
                        } catch (err) {
                            return yupToFormErrors(err);
                        }
                    }}
                    onSubmit={handleSubmit}>
                    {({ submitForm, touched }) => (
                        <Form>
                            <PromptIfDirty />
                            <Container className='container-title-layout'>
                                <h3>Général</h3>
                                <Row className='form-group d-flex align-items-center mb-3'>
                                    <Col>
                                        <label htmlFor='date'>Date</label>
                                    </Col>
                                    <Col className='input-group'>
                                        <Field
                                            name='date'
                                            type='text'
                                            className='form-control shadow-sm'
                                            id='date'
                                            value={dateTransformer(new Date())}
                                            readOnly
                                        />
                                    </Col>
                                </Row>
                                <Row className='form-group required d-flex align-items-center mb-3'>
                                    <Col>
                                        <label className='required' htmlFor='nbHours'>
                                            Heures d'utilisation
                                        </label>
                                    </Col>
                                    <Col>
                                        <FormRow name='nbHours'>
                                            <Field
                                                id='nbHours'
                                                type='number'
                                                className='form-control shadow-sm'
                                            />
                                        </FormRow>
                                    </Col>
                                </Row>
                                <hr />
                                <h3>Contrôles vibratoires</h3>
                                <FieldVibrationControls />
                                <hr />
                                <h3>Points de contrôles</h3>
                                <div className='mb-3'>
                                    <FieldCheckpointList type={intervention?.type as string} />
                                </div>
                                <FormRow
                                    name='checkpointsComment'
                                    label='Commentaire'
                                    errorProps={{
                                        show: getValueAtPath(touched, "checkpoints") !== null,
                                    }}>
                                    <Field
                                        as='textarea'
                                        rows={5}
                                        id='checkpointsComment'
                                        className='form-control textarea-resize'
                                    />
                                </FormRow>
                                <hr />
                                <h3>Accessoires</h3>
                                <div className='mb-3'>
                                    <FieldAccessoryList />
                                </div>
                                <FormRow name='accessoriesComment' label='Commentaire'>
                                    <Field
                                        as='textarea'
                                        rows={5}
                                        id='accessoriesComment'
                                        className='form-control textarea-resize'
                                    />
                                </FormRow>
                                <hr />
                                <h3>Photos</h3>
                                <FieldGallery />
                                <FooterLayout style={{ height: "100px" }}>
                                    <Container fluid>
                                        <Row>
                                            <Col className='text-center'>
                                                <Button
                                                    variant='primary'
                                                    className='w-100'
                                                    onClick={() => {
                                                        signAfterSubmit = false;
                                                        submitForm();
                                                    }}>
                                                    Enregistrer et fermer
                                                </Button>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className='text-center'>
                                                <Button
                                                    variant='primary'
                                                    type='submit'
                                                    className='w-100 mt-2'
                                                    onClick={() => {
                                                        signAfterSubmit = true;
                                                        submitForm();
                                                    }}>
                                                    Enregistrer et signer
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Container>
                                </FooterLayout>
                            </Container>
                        </Form>
                    )}
                </Formik>
            )}
        </TitleLayout>
    );
};

export default RentalInterventionEdit;
