import { FC } from "react";
import { IOverlayLoading } from "../interfaces/component.props/IOverlayLoading";
import { OverlayLoadingStyled } from "./Styled/Overlay.Loading";
import { Button, ProgressBar, Spinner } from "react-bootstrap";

const OverlayLoading: FC<IOverlayLoading> = ({ isOpen, progressPercent, forceClose }) => {

    return (
        <OverlayLoadingStyled isOpenedOverlay={isOpen} className="d-flex flex-column">
            {progressPercent !== undefined ? (
                <ProgressBar
                    className='w-75'
                    striped={true}
                    now={progressPercent}
                    label={`${Math.round(progressPercent)} %`}
                />
            ) : (
                <Spinner animation='border' variant='primary' />
            )}
            {forceClose !== undefined && (                
                <div>
                    <Button 
                        variant='danger'
                        type="button"
                        className='mt-5'
                        onClick={forceClose} 
                    >
                        Forcer la fermeture
                    </Button>
                </div>
            )}
        </OverlayLoadingStyled>
    );
};

export default OverlayLoading;
