import * as Yup from "yup";
import vibrationControlsValidationSchema from "./vibrationControls.schema";
import { IVibrationControlsModel } from "../interfaces/model";

export const maintenanceEditChildMaterialValidationSchema = Yup.object().shape({
    vibrationControlsBeforeAssembly: Yup.object().when(
        ["centrifuge"], {
        is: (centrifuge: any) => centrifuge === true,
        then: vibrationControlsValidationSchema,
        otherwise: Yup.object().nullable().notRequired(),
    }),
    vibrationControlsAfterAssembly: Yup.object().when(
        ["centrifuge"], {
        is: (centrifuge: any) => centrifuge === true,
        then: vibrationControlsValidationSchema,
        otherwise: Yup.object().nullable().notRequired(),
    }),
    vibrationControlsComment: Yup.string().nullable().when(
        ["vibrationControlsBeforeAssembly", "vibrationControlsAfterAssembly", "centrifuge"],
        {
            is: (
                vibrationControlsBeforeAssembly: IVibrationControlsModel | null,
                vibrationControlsAfterAssembly: IVibrationControlsModel | null,
                centrifuge: boolean
            ) => {
                const res = centrifuge && (!vibrationControlsBeforeAssembly ||
                    vibrationControlsBeforeAssembly.cannotBeDone ||
                    !vibrationControlsAfterAssembly ||
                    vibrationControlsAfterAssembly.cannotBeDone);
                return res;
            },
            then: Yup.string().required(
                "Expliquez pourquoi vous n'avez pas effectué tous les contrôles"
            ),
            otherwise: Yup.string().nullable().notRequired(),
        }
    ),
    interventionType: Yup.string().required("Champs requis"),
});