import { FC } from "react";
import { IViewerListItem } from "../../../interfaces/component.props/viewer/list/IViewerListItem";
import {
    materialStateLabel,
    materialStateColor,
    translateMaterialInterventionType,
} from "../../../utils";
import { Badge, Col, ListGroupItem, Row } from "react-bootstrap";

const ListItemViewer: FC<IViewerListItem> = ({
    materialChildrensMappingHierarchy,
    maintenance,
}) => {
    const handleGreaseReplacementBadge = (materialChildren: any) => {
        if (materialChildren.isGreaseReplacement !== null && materialChildren.isGreaseReplacement !== undefined) {
            if (materialChildren.isGreaseReplacement) {
                return <Badge bg='primary mt-2'>Remplacement des graisses</Badge>;
            } else {
                return <Badge bg='primary mt-2'>Graisses non-remplacées</Badge>;
            }
        } else {
            if (maintenance.materialItems === undefined) {
                return;
            }

            for (const materialItem of maintenance.materialItems) {
                if (materialItem.material.id === materialChildren.id && materialItem.isGreaseReplacement !== null) {
                    if (materialItem.isGreaseReplacement) {
                        return <Badge bg='primary mt-2'>Remplacement des graisses</Badge>;
                    } else {
                        return <Badge bg='primary mt-2'>Graisses non-remplacées</Badge>;
                    }
                }
            }
        }
    };

    return (
        <>
            {materialChildrensMappingHierarchy.map((materialChildren: any, index: number) => (
                <ListGroupItem variant={index % 2 === 0 ? "light" : "dark"} as='li' key={index}>
                    <Row>
                        <Col className='col-lg-8'>
                            {materialChildren.material?.name ?? materialChildren.name} -
                            <i>
                                {" "}
                                Ref :{" "}
                                {materialChildren.material?.reference ?? materialChildren.reference}
                            </i>
                        </Col>
                        <Col className='text-center col-lg-2'>
                            {materialChildren.state ? (
                                <Badge
                                    bg={materialStateColor(materialChildren.state)}>
                                    {materialStateLabel(materialChildren.state)}
                                </Badge>
                            ) : (
                                maintenance.materialItems?.map((item: any) =>
                                    item.material.id === materialChildren.id && (
                                        <Badge
                                            key={materialChildren.id}
                                            bg={materialStateColor(item.state)}>
                                            {materialStateLabel(item.state)}
                                        </Badge>
                                    )
                                )
                            )}
                        </Col>
                        <Col className='d-flex flex-column text-center col-lg-2'>
                            {materialChildren.interventionType ? (
                                <Badge bg='primary'>
                                    {translateMaterialInterventionType(materialChildren.interventionType)}
                                </Badge>
                            ) : (
                                maintenance.materialItems?.map((item: any) =>
                                    item.material.id === materialChildren.id && (
                                        <Badge key={materialChildren.id} bg='primary'>
                                            {translateMaterialInterventionType(item.interventionType)}
                                        </Badge>
                                    )
                                )
                            )}
                            {handleGreaseReplacementBadge(materialChildren)}
                        </Col>
                    </Row>
                </ListGroupItem>
            ))}
        </>
    );
};

export default ListItemViewer;
