import { Field } from "formik";
import { FC } from "react";
import { formNameToId, materialStateLabel, materialStates, materialStateColor } from "../../utils";

const FieldMaterialState: FC<{ name?: string, nullable?: boolean }> = ({ name, nullable }) => {
    const states = [];
    const isNullable = nullable !== undefined ? nullable : false;

    if (isNullable) {
        states.push({
            key: null,
            label: 'Non renseigné',
            color: '',
        });
    }

    for (let key in materialStates) {
        states.push({
            key,
            label: materialStateLabel(key),
            color: materialStateColor(key),
        });
    }


    return (
        <div className='d-flex w-100'>
            {states.map((s) => {
                const id = formNameToId(name!) + '_' + s.key;
                return (
                    <div
                        className='d-flex justify-content-center align-items-center mx-2'
                        key={s.key}
                    >
                        <Field
                            type='radio'
                            className={'form-check-input me-2 mt-0 bg-' + s.color}
                            name={name}
                            id={id}
                            value={s.key}
                        />
                        <label className='form-check-label' htmlFor={id}>
                            {s.label}
                        </label>
                    </div>
                )
            })}
        </div>
    );
};

export default FieldMaterialState;