import { IIdAware, ISyncStateAware } from "../interfaces/model";

export const syncStates = {
    awaiting: "awaiting",
    failed: "failed",
    succeded: "succeded",
};

export const syncStateLabel = (state: string): string => {
    switch (state) {
        case syncStates.succeded:
            return "Synchronisé";
        case syncStates.failed:
            return "Echec";
        case syncStates.awaiting:
            return "En attente";
        default:
            return "Synchronisé";
    }
};

export const isSyncable = (object: ISyncStateAware) => {
    return object.syncState === syncStates.failed || object.syncState === syncStates.awaiting;
};

export const syncStateColor = (state: string): string => {
    switch (state) {
        case syncStates.succeded:
            return "success";
        case syncStates.failed:
            return "danger";
        case syncStates.awaiting:
            return "info";
        default:
            return "success";
    }
};

export const getResourceId = (input: any | string | number | null) => {
    let id = input;
    if (id === null) {
        return null;
    }

    if (typeof input === "object") {
        if (input.id !== undefined) {
            id = input.id;
        } else if (input["@id"] !== undefined) {
            id = input["@id"];
        } else {
            return null;
        }
    }

    if (typeof id === "string") {
        if (id.match(/\/api\//)) {
            id = id.split("/").slice(-1)[0];
        }
    }

    if (id !== null) {
        id = parseInt(id);
    }

    return id;
};

export const isNewResource = (resource: IIdAware) => {
    return resource.id === undefined || !Number.isInteger(resource.id);
};

export const transformObjectApiFormat = (object: any, config: any[], method: string) => {
    if (object === null) {
        return;
    }

    const newObject = { ...object };

    config.forEach((configItem) => {
        const splitPath = configItem.path.split(".");

        const key = splitPath.shift() as string;

        if (newObject[key] === undefined) {
            return;
        }

        if (configItem.method !== undefined && configItem.method !== method) {
            return;
        }

        // Get current value
        const value = newObject[key];

        if (Array.isArray(value)) {
            newObject[key] = value.map((valueItem) => {
                let path = splitPath.join(".");
                let endpoint = false;

                if (!path) {
                    path = "value";
                    endpoint = true;
                    valueItem = { value: valueItem };
                }

                const res = transformObjectApiFormat(valueItem, [{ ...configItem, path }], method);

                return endpoint ? res.value : res;
            });

            const nullFilter = newObject[key].filter((v: any) => v !== null);
            newObject[key] = [...nullFilter];
        } else {
            // Last key of paths
            if (splitPath.length === 0) {
                // Set the iri
                if (configItem.type === "transformIri") {
                    const resourceId = getResourceId(value);
                    newObject[key] =
                        resourceId !== null ? configItem.config.baseIri + "/" + resourceId : null;
                } else if (configItem.type === "addIri") {
                    const resourceId = getResourceId(value);
                    if (resourceId !== null) {
                        newObject[key]['@id'] = configItem.config.baseIri + "/" + resourceId;
                    }

                } else if (configItem.type === "transformBase64") {
                    newObject[key] = value.split(",")[1];
                } else if(configItem.type === "unset") {
                    newObject[key] = null;
                }
            } else {
                // Embeded object: go deeper
                newObject[key] = transformObjectApiFormat(value, [
                    { ...configItem, path: splitPath.join(".") },
                ], method);
            }
        }
    });

    return newObject;
};
