import { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TitleLayout } from "../components/Styled/Title.Layout";
import NavbarMenu from "../components/Navbar/Navbar.menu";
import { Field, FieldArray, Form, Formik } from "formik";
import PromptIfDirty from "../components/PromptIfDirty";
import { FooterLayout } from "../components/Styled/Footer.Layout";
import { Button, Container } from "react-bootstrap";
import FormRow from "../components/Form/FormRow";
import { createNewMaintenance, dateTransformer, syncStates } from "../utils";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import { IMaterialState, IUserState } from "../interfaces/state";
import FieldCountry from "../components/Form/FieldCountry";
import ExtraSpareParts from "../components/ExtraSpareParts";
import { updateOrCreateMaintenance } from "../redux/actions";
import { nanoid } from "nanoid";
import { selectMaintenanceById } from "../redux/selectors";
import FieldNbHours from "../components/Form/FieldNbHours";
import MaintenanceMaterialList from "../components/Form/Maintenance/MaintenanceMaterialList";
import { validationMaintenanceSchema } from "../validation/maintenance.schema";

const MaintenanceEdit: FC = () => {

    const { id } = useParams();
    const userConnected = useSelector<RootState, IUserState>((state) => state.userLogin);
    const state = useSelector<RootState, RootState>((state) => state);
    const { material } = useSelector<RootState, IMaterialState>((state) => state.material)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let signAfterSubmit: boolean = false;
    let maintenance = null;

    if (id !== undefined) {
        maintenance = selectMaintenanceById(state, id);
    } else {
        maintenance = createNewMaintenance({
            technician: {
                ...userConnected.userData?.user,
            },
        });
    }

    const handleSubmitMaintenance = (values: any) => {
        const newMaintenance = {
            ...values,
            id: values.id ?? nanoid(),
            syncState: syncStates.awaiting,
            material: { ...material }
        }

        // Save in maintenances states
        dispatch(updateOrCreateMaintenance(newMaintenance));
        navigate(signAfterSubmit ? `/maintenance/${newMaintenance.id}/signatures` : "/maintenance/list");
    }

    if (!maintenance) {
        navigate("/maintenance/list");
        return (<></>);
    }

    return (
        <>
            <TitleLayout>
                <NavbarMenu navbarTitle='Saisie maintenance' />
                <h2 className='title'>Édition maintenance</h2>
                <Formik
                    enableReinitialize={true}
                    initialValues={maintenance}
                    validationSchema={validationMaintenanceSchema}
                    onSubmit={async (values, { setStatus }) => {
                        setStatus({ success: true });
                        handleSubmitMaintenance(values);
                    }}>
                    {({ values, submitForm }) => (
                        <Form>
                            <PromptIfDirty />
                            <Container className='container-title-layout'>

                                <FormRow label='Date' name='date' className="d-flex mb-3" labelProps={{ style: { flexBasis: '100%' } }}>
                                    <Field
                                        type='text'
                                        className='form-control shadow-sm'
                                        value={dateTransformer(new Date())}
                                        readOnly
                                    />
                                </FormRow>
                                <FormRow label='Rue' name='address.street' className="d-flex mb-3" labelProps={{ style: { flexBasis: '100%' } }}>
                                    <Field type='text' className='form-control shadow-sm' />
                                </FormRow>
                                <FormRow label='Code postal' name='address.postcode' className="d-flex  mb-3" labelProps={{ style: { flexBasis: '100%' } }}>
                                    <Field type='text' className='form-control shadow-sm' />
                                </FormRow>
                                <FormRow label='Ville' name='address.city' className="d-flex mb-3" labelProps={{ style: { flexBasis: '100%' } }}>
                                    <Field type='text' className='form-control shadow-sm' />
                                </FormRow>
                                <FormRow label='Pays' name='address.countryCode' className="d-flex mb-3" labelProps={{ style: { flexBasis: '100%' } }}>
                                    <FieldCountry className='form-control shadow-sm' />
                                </FormRow>
                                <FormRow label="Heures d'utilisation" name='nbHours' className="d-flex mb-3" labelProps={{ style: { flexBasis: '100%' } }}>
                                    <Field type='number' className='form-control shadow-sm' />
                                </FormRow>
                                <FormRow label="Type de maintenance" name='maintenanceNbHours' className="d-flex mb-3" labelProps={{ style: { flexBasis: '100%' } }}>
                                    <FieldNbHours />
                                </FormRow>

                                <div className="mt-3">
                                    <MaintenanceMaterialList />
                                </div>

                                <label><b>Commentaires</b></label>
                                {/* MATERIAL CHILDREN COMMENT */}
                                <FormRow label={false} name='materialItemsComment'>
                                    <Field
                                        as='textarea'
                                        className='form-control shadow-sm'
                                        placeholder='Commentaires contrôle pièces'
                                    />
                                </FormRow>
                                <FormRow label={false} name='greaseReplacementComment'>
                                    <Field
                                        as='textarea'
                                        className='form-control shadow-sm'
                                        placeholder='Références des graisses remplacées (si nécessaire)'
                                    />
                                </FormRow>
                                <FormRow label={false} name='comment'>
                                    <Field
                                        as='textarea'
                                        className='form-control shadow-sm'
                                        placeholder='Commentaires généraux'
                                    />
                                </FormRow>

                                <hr className='mt-4 mb-3' />
                                {/* EXTRA SPARE PARTS */}

                                <FieldArray
                                    name='extraSpareParts'
                                    render={(arrayHelpers) => (
                                        <ExtraSpareParts
                                            arrayHelpers={arrayHelpers}
                                            values={values}
                                        />
                                    )}
                                />

                                <FooterLayout style={{ height: "100px" }}>
                                    <Button
                                        variant='primary'
                                        className='w-100'
                                        onClick={() => {
                                            signAfterSubmit = false;
                                            submitForm();
                                        }}>
                                        Enregistrer et fermer
                                    </Button>
                                    <Button
                                        variant='primary'
                                        type='submit'
                                        className='w-100 mt-2'
                                        onClick={() => {
                                            signAfterSubmit = true;
                                            submitForm();
                                        }}>
                                        Enregistrer et signer
                                    </Button>
                                </FooterLayout>
                            </Container>
                        </Form>
                    )}
                </Formik>
            </TitleLayout>
        </>
    )
}

export default MaintenanceEdit;