import { RENTAL_FETCH_SUCCESS, RENTAL_RESET } from "../reducer.types";

import { IRentalState } from "../../interfaces/state";
import { IReduxAwareAction } from "../../interfaces/action";

// Rental Reducer
export const rentalReducer = (
    state: IRentalState = { rental: null },
    action: IReduxAwareAction
) => {
    switch (action.type) {

        case RENTAL_FETCH_SUCCESS:
            return {
                loading: false,
                error: null,
                rental: action.payload[0],
            };

        case RENTAL_RESET:
            return {
                loading: false,
                error: null,
                rental: null,
            };

        default:
            return state;
    }
};
