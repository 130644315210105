import { apiBaseUrl, defaultSaveConfig } from "./config.api.baseUrl";
import { IRentalModel, IInterventionModel } from "../interfaces/model";
import { baseMaterialIri } from "./config.api.material";
import { baseAccessoryIri } from "./config.api.accessories";
import { baseCheckpointIri } from "./config.api.checkpoints";
import { baseRentalIri } from "./config.api.rental";
import { baseUserIri } from "./config.api.user";
import { baseMediaObjectIri } from "./config.api.mediaObject";

export const baseRentalInterventionIri = "/api/rental_interventions";

export const interventionConfigApiFormat = [
    { path: "material", config: { baseIri: baseMaterialIri }, type: "transformIri" },
    { path: "rental", config: { baseIri: baseRentalIri }, type: "transformIri" },
    { path: "technician", config: { baseIri: baseUserIri }, type: "transformIri" },
    { path: "accessories.accessory", config: { baseIri: baseAccessoryIri }, type: "transformIri" },
    { path: "checkpoints.checkpoint", config: { baseIri: baseCheckpointIri }, type: "transformIri" },
    { path: "gallery.images", config: { baseIri: baseMediaObjectIri }, type: "addIri", method: "PATCH" },
    { path: "gallery.images", config: { baseIri: baseMediaObjectIri }, type: "transformIri", method: "POST" },
];

// Create Get Request for Rentals Interventions by Rental Id
export const apiGetRentalsInterventionsByRentalId = async (
    userToken: string,
    rentalId: IRentalModel["id"]
) => {
    const response = await apiBaseUrl(userToken).get(
        `${baseRentalInterventionIri}?rental.id=${rentalId}`
    );
    return response.data;
};

export const apiSaveIntervention = (userToken: string, intervention: IInterventionModel) => {
    return apiBaseUrl(userToken)(
        defaultSaveConfig(intervention, baseRentalInterventionIri, interventionConfigApiFormat)
    );
};

export const apiDeleteIntervention = (userToken: string, intervention: IInterventionModel) => {
    return apiBaseUrl(userToken).delete(`${baseRentalInterventionIri}/${intervention.id}`, {
        headers: {
            Authorization: `Bearer ${userToken}`,
        },
    });
};
