import { RootState } from "../../store";
import { createSelector } from "@reduxjs/toolkit";
import { IMaintenancesState } from "../../interfaces/state";
import { isSyncable } from "../../utils";

const selectMaintenances = (state: RootState): IMaintenancesState => state.maintenances;
const selectMaintenanceId = (_: any, maintenanceId: string | undefined): string | undefined => maintenanceId;

export const selectMaintenanceById = createSelector(
    [selectMaintenances, selectMaintenanceId],
    (maintenances, maintenanceId) => {
        const maintenance = maintenances.maintenances?.find((maintenance) => maintenance.id == maintenanceId);
        return maintenance ? maintenance : null;
    }
);

export const selectMaintenancesList = createSelector([selectMaintenances], (maintenances) => maintenances.maintenances);

export const selectMaintenancesToSync = createSelector(
    [selectMaintenances],
    (maintenances) => maintenances.maintenances?.filter((maintenance) => isSyncable(maintenance))
);
