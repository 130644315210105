import { RootState } from "../../store";
import { createSelector } from "@reduxjs/toolkit";
import { IAccessoriesState } from "../../interfaces/state";

const selectAccessories = (state: RootState): IAccessoriesState => state.accessories;

export const selectAccessoriesList = createSelector([selectAccessories], (accessories) => {
    if (accessories.accessories) {
        return accessories.accessories.length > 0 ? accessories.accessories : [];
    } else {
        return [];
    }
});
