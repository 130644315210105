import { FC, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { RootState } from "../store";
import { useDispatch, useSelector } from "react-redux";
import NavbarMenu from "../components/Navbar/Navbar.menu";
import { FooterLayout } from "../components/Styled/Footer.Layout";
import { TitleLayout } from "../components/Styled/Title.Layout";
import { IInterventionModel, IUserModel } from "../interfaces/model";
import {
    rentalStateLabel,
    interventionTypeLabel,
    availableInterventionsTypes,
    canEditIntervention,
    canDeleteIntervention,
    isInterventionComplete,
    operationHoursLabel,
    isSyncable,
    userToString,
    getValueAtPath,
    dateTransformer,
    canBeMultiple,
} from "../utils";
import { Button, Col, Container, Dropdown, Modal, Row, Table } from "react-bootstrap";
import {
    EyeFill,
    InfoCircle,
    Pencil,
    PencilSquare,
    PlusCircle,
    Trash,
} from "react-bootstrap-icons";
import SyncState from "../components/SyncState";
import {
    selectUser,
    selectUserToken,
    selectInterventionsList,
    selectRentalData,
} from "../redux/selectors";
import { deleteIntervention, saveInterventions } from "../redux/actions";
import OverlayLoading from "../components/OverlayLoading";
import ModalConfirmation from "../components/Modal/Modal.confirmation";
import BooleanBadge from "../components/BooleanBadge";
import NotFound from "../components/NotFound";
import { INTERVENTIONS_LOADING_CANCEL } from "../redux/reducer.types";

const RentalHome: FC = () => {
    const navigate = useNavigate();
    const state = useSelector<RootState, RootState>((state) => state);
    const dispatch = useDispatch();
    const userToken = selectUserToken(state);
    const user = selectUser(state) as IUserModel;
    const { loading, progressApiCall } = state.interventions;
    const [currentError, setCurrentError] = useState<any>(null);
    const rental = selectRentalData(state);
    const interventions = selectInterventionsList(state);
    const types = availableInterventionsTypes(interventions ? interventions : []);
    const showSyncBtn = interventions.filter((i) => isSyncable(i)).length > 0;
    const violations = getValueAtPath(currentError, "responseError.data.violations") as
        | null
        | any[];
    const errorDetail = currentError && typeof currentError.responseError === 'string' ?
        currentError.responseError :
        getValueAtPath(currentError, "responseError.data.detail");

    const [confirmDelete, setConfirmDelete] = useState<any>({ show: false });

    if (!rental) {
        return (
            <NotFound
                title='Aucune location en cours'
                message="Vous n'avez aucune location en cours, veuillez renseigner une location sur l'administration pour commencer."
            />
        );
    }

    const isEnable = (type: string): boolean => {
        return !(
            canBeMultiple(type) ||
            interventions.find((intervention) => intervention.type === type) === undefined
        );
    };

    return (
        <>
            <OverlayLoading 
                isOpen={loading} 
                progressPercent={progressApiCall} 
                forceClose={() => dispatch({ type: INTERVENTIONS_LOADING_CANCEL})}    
            />
            <TitleLayout>
                <NavbarMenu navbarTitle='Location' />
                <h2 className='title'>Dernière location</h2>
                <Container className='container-title-layout'>
                    <Table>
                        <tbody>
                            <tr>
                                <th>Dates</th>
                                <td>
                                    Du {dateTransformer(rental.startDate)} au{" "}
                                    {dateTransformer(rental.endDate)}
                                </td>
                            </tr>
                            <tr>
                                <th>Fonctionnement horaire</th>
                                <td>{operationHoursLabel(rental.operatingHours)} </td>
                            </tr>
                            <tr>
                                <th>État:</th>
                                <td>{rentalStateLabel(rental.state)}</td>
                            </tr>
                            {rental.customer && (
                                <tr>
                                    <th>Client</th>
                                    <td>{rental.customer.company}</td>
                                </tr>
                            )}
                            {rental.address && (
                                <tr>
                                    <th>Adresse</th>
                                    <td>
                                        {rental.address.street}
                                        <br />
                                        {rental.address.postcode} {rental.address.city} <br />
                                        {rental.address.countryCode}
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                    <h2>Historique des interventions</h2>
                    {interventions && interventions.length ? (
                        <Table responsive striped bordered hover>
                            <thead>
                                <tr>
                                    <th className='text-center'>Date</th>
                                    <th className='text-center'>Tech</th>
                                    <th className='text-center'>Type</th>
                                    <th className='text-center'>État</th>
                                    <th className='text-center'>Complète</th>
                                    <th className='text-center'>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {interventions?.map(
                                    (intervention: IInterventionModel, index: number) => (
                                        <tr key={intervention.id}>
                                            <td className='text-center'>
                                                <Link
                                                    to={`/rental/intervention/${intervention.id}/show`}>
                                                    {dateTransformer(intervention.date)}
                                                </Link>
                                            </td>
                                            <td className='text-center'>
                                                {userToString(intervention.technician)}
                                            </td>
                                            <td className='text-center'>
                                                {interventionTypeLabel(intervention.type)}
                                            </td>
                                            <td className='text-center'>
                                                <SyncState state={intervention.syncState!} />
                                            </td>
                                            <td className='text-center'>
                                                <BooleanBadge
                                                    value={isInterventionComplete(intervention)}
                                                />
                                            </td>
                                            <td className='text-center'>
                                                <Dropdown>
                                                    <Dropdown.Toggle size='sm' id='dropdown-basic'>
                                                        Actions
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item
                                                            onClick={() => {
                                                                navigate(
                                                                    `/rental/intervention/${intervention.id}/show`
                                                                );
                                                            }}>
                                                            <EyeFill /> Voir
                                                        </Dropdown.Item>
                                                        {canEditIntervention(
                                                            intervention,
                                                            user
                                                        ) && (
                                                                <Dropdown.Item
                                                                    onClick={() => {
                                                                        navigate(
                                                                            `/rental/intervention/${intervention.id}/edit`
                                                                        );
                                                                    }}>
                                                                    <Pencil /> Modifier
                                                                </Dropdown.Item>
                                                            )}
                                                        {canEditIntervention(
                                                            intervention,
                                                            user
                                                        ) && (
                                                                <Dropdown.Item
                                                                    onClick={() => {
                                                                        navigate(
                                                                            `/rental/intervention/${intervention.id}/signatures`
                                                                        );
                                                                    }}>
                                                                    <PencilSquare /> Signer
                                                                </Dropdown.Item>
                                                            )}
                                                        {intervention.error && (
                                                            <Dropdown.Item
                                                                onClick={() =>
                                                                    setCurrentError(
                                                                        intervention.error
                                                                    )
                                                                }>
                                                                <InfoCircle /> Erreurs
                                                            </Dropdown.Item>
                                                        )}
                                                        {canDeleteIntervention(
                                                            intervention,
                                                            user
                                                        ) && (
                                                                <Dropdown.Item
                                                                    onClick={() =>
                                                                        setConfirmDelete({
                                                                            show: true,
                                                                            confirmCallBack: () => {
                                                                                dispatch(
                                                                                    deleteIntervention(
                                                                                        intervention,
                                                                                        index,
                                                                                        userToken!
                                                                                    )
                                                                                );
                                                                                setConfirmDelete({
                                                                                    show: false,
                                                                                });
                                                                            },
                                                                        })
                                                                    }>
                                                                    <Trash /> Supprimer
                                                                </Dropdown.Item>
                                                            )}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </td>
                                        </tr>
                                    )
                                )}
                            </tbody>
                        </Table>
                    ) : (
                        <p>Aucune intervention</p>
                    )}
                    {types.length > 0 && (
                        <>
                            <b>Créer une nouvelle intervention:</b> <br />
                            {types.map((type, index) => (
                                <Button
                                    key={type}
                                    className='me-3'
                                    size='sm'
                                    variant='primary'
                                    disabled={isEnable(type)}
                                    onClick={() => {
                                        navigate(`/rental/intervention/create/${type}`);
                                    }}>
                                    <PlusCircle className='me-2' />
                                    {interventionTypeLabel(type)}
                                </Button>
                            ))}
                        </>
                    )}
                </Container>
                {showSyncBtn && (
                    <FooterLayout style={{ height: "55px" }}>
                        <Container fluid>
                            <Row>
                                <Col className='text-center'>
                                    <Button
                                        variant='primary'
                                        className='w-100 mt-2'
                                        onClick={() =>
                                            dispatch(saveInterventions(userToken!, interventions))
                                        }>
                                        Synchroniser
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                    </FooterLayout>
                )}
            </TitleLayout>
            <ModalConfirmation
                title='Confirmation'
                textContent='Êtes vous sûr de vouloir supprimer cette intervention'
                closeCallBack={() =>
                    setConfirmDelete((prevState: any) => ({
                        ...prevState,
                        show: false,
                    }))
                }
                {...confirmDelete}
            />
            <Modal show={currentError !== null} onHide={() => setCurrentError(null)}>
                <Modal.Header closeButton>
                    <Modal.Title>Erreur</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{currentError?.cathError?.message}</p>
                    <ul>
                        {violations && violations.length !== undefined && violations.length ? (
                            violations.map((violation) => (
                                <li className='text-danger'>
                                    {violation.propertyPath}: {violation.message}
                                </li>
                            ))
                        ) : null}
                        {errorDetail && (
                            <p className='text-danger'>
                                Une erreur est survenue lors de la synchronisation<br />
                                {errorDetail}
                            </p>
                        )}
                    </ul>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={() => setCurrentError(null)}>
                        Fermer
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default RentalHome;
