import styled from "styled-components";

export const BaseLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  &:after {
    content: "v${process.env.REACT_APP_VERSION}";
  }
`;
