import { IImagesModel } from "../interfaces/model";
import { apiBaseUrl, defaultSaveConfig } from "./config.api.baseUrl";

export const baseMediaObjectIri = '/api/media_objects';

export const imageConfigApiFormat = [
    { path: "base64", type: "transformBase64" },
];

// Create Post Request for saving Image
export const apiSaveImage = async (userToken: string, image: IImagesModel) => {
    const response = apiBaseUrl(userToken)(
        defaultSaveConfig(image, baseMediaObjectIri, imageConfigApiFormat)
    );
    return response;
};
